.about-content {
    padding-right: 130px;

    .section-title {
        margin-bottom: 35px;
    }

    p {
        margin-bottom: 40px;
    }

    .singiture {
        h4 {
            font-size: 20px;
            margin-bottom: 25px;
        }
    }
}

@media (max-width: 1200px) {
    .about-content-two .section-title .title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 991px) {
    .about {
        padding-top: 76px;
    }

    .about-content {
        padding-right: 0;
    }

    .about-thumb {
        margin-top: 30px;
    }

    .about-two {
        padding-bottom: 80px;
    }

    .about-content-two {
        margin-top: 40px;
    }

    .about-tax {
        padding: 60px 0 80px;       
    }

    .about-tax-content {
        padding-left: 0;
        margin-top: 40px;
    }
}