.modal {
    .modal-header {
        .close {
            background: transparent;
            border: 0;
            padding: 1rem 1rem;
            margin: -1rem -1rem -1rem auto;
            span {
                float: right;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1;
                color: #000;
                text-shadow: 0 1px 0 #fff;
                opacity: 0.5;
            }
        }
    }
}
