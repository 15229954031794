/* Fool-proof @font-face */
/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */
/* Front */
@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@-moz-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@-ms-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@-o-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
.element {
  width: 100px;
  height: 100px;
  background: black;
  -webkit-animation: slide-down 5s 3;
  -moz-animation: slide-down 5s 3;
  -ms-animation: slide-down 5s 3;
  -o-animation: slide-down 5s 3;
  animation: slide-down 5s 3;
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #797687;
  overflow-x: hidden;
}

::-moz-selection {
  background: yellow;
}

::selection {
  background: yellow;
}

svg {
  display: block;
}

a,
button,
input,
textarea,
button,
select {
  transition: all 0.3s ease-in-out;
}

button {
  cursor: pointer;
  outline: 0;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px 12px;
  outline: 0;
}

a,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  font-weight: 600;
  color: #2b2350;
}

img {
  max-width: 100%;
  height: auto;
}

.pr {
  position: relative;
}

.pt-7 {
  padding-top: 70px;
}

span.help-block {
  padding-left: 10px;
  font-size: 13px;
  letter-spacing: 1px;
}
span.help-block.error {
  color: #db1e1e;
}

.container-wrap {
  max-width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
}
.container-wrap.bg-color-one {
  background-color: #f6faf8;
}
.container-wrap.bg-color-two {
  background-color: #d9e6ff;
}
.container-wrap.bg-footer-color {
  background-color: #1d2146;
}

section {
  position: relative;
}

.mw-none {
  max-width: unset !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 991px) {
  .pr-85 {
    padding-right: 85px;
  }
  .pl-85 {
    padding-left: 85px;
  }
}
.section-small {
  margin-bottom: 80px;
}
.section-small .title {
  font-size: 20px;
  font-weight: 500;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.section-padding {
  padding: 100px 0;
}

.no-scroll {
  overflow-y: hidden !important;
}

.container-wide {
  max-width: 1700px;
  padding: 0 15px;
  margin: 0 auto;
}

.gutters-10 > [class*=col-] {
  padding: 0 5px;
}

@media (max-width: 991px) {
  .pix-order-two {
    order: 2;
  }
  .pix-order-one {
    order: 1;
  }
}
/*--------------------------------------------------------------
  ##  Buttons
  --------------------------------------------------------------*/
.pix-btn {
  padding: 8px 30px;
  background: #ffc107;
  border-radius: 30px;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
.pix-btn.submit-btn {
  border: 0;
  padding: 11px 35px;
}
.pix-btn.submit-btn:focus {
  outline: 0;
}
.pix-btn.submit-btn i {
  display: none;
}
.pix-btn.submit-btn.clicked {
  min-width: 210px;
  min-height: 50px;
}
.pix-btn.submit-btn.clicked i {
  display: block;
}
.pix-btn.submit-btn.clicked .btn-text {
  display: none;
}
.pix-btn.btn-two {
  border-radius: 30px;
  background: #f97bc5;
  box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
  border: 1px solid #f97bc5;
  font-weight: 500;
  font-size: 16px;
}
.pix-btn.btn-two i {
  display: inline-block;
  vertical-align: middle;
}
.pix-btn.btn-two:hover {
  background: transparent;
  color: #f97bc5;
  border-color: #f97bc5;
}
.pix-btn.btn-two.btn-outline {
  border: 2px solid rgba(249, 123, 197, 0.5);
  color: #f97bc5;
}
.pix-btn.btn-two.btn-outline:hover {
  background: #f97bc5;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(235, 110, 183, 0.3);
}
.pix-btn.btn-large {
  padding: 16px 44px;
  font-size: 16px;
}
.pix-btn.plus-icon {
  padding: 8px 64px 8px 41px;
  position: relative;
}
.pix-btn.plus-icon i {
  margin-left: 10px;
  font-size: 20px;
  display: inline-block;
  position: absolute;
  right: 32px;
  top: 11px;
}
.pix-btn.btn-big {
  padding: 16px 47px;
  font-size: 16px;
}
.pix-btn.color-two {
  background: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}
.pix-btn.btn-grey {
  background: #f3f3f4;
  color: #322d49;
  box-shadow: none;
  border-radius: 4px;
  padding: 11px 40px;
}
.pix-btn.btn-grey:hover {
  background: #fd4d5c;
}
.pix-btn.btn-grey.color-three:hover {
  background: #12bf7c;
  box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
}
.pix-btn:hover, .pix-btn:focus {
  color: #fff;
  box-shadow: none;
  outline: 0;
}
.pix-btn.btn-outline {
  border: 1px solid #ffc107;
  background: transparent;
  color: #ffc107;
  box-shadow: none;
}
.pix-btn.btn-outline:hover {
  background: #ffc107;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
.pix-btn.btn-outline-two {
  border: 1px solid #7052fb;
  background: transparent;
  color: #7052fb;
  box-shadow: none;
}
.pix-btn.btn-outline-two:hover {
  background: #7052fb;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}
.pix-btn.btn-light {
  background: #fff;
  color: #ffc107;
  border: 2px solid #fff;
  padding: 14px 40px;
}
.pix-btn.btn-light:focus {
  box-shadow: none;
  outline: 0;
}
.pix-btn.btn-light.color-two {
  color: #7052fb;
}
.pix-btn.btn-light:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
.pix-btn.btn-light.btn-outline {
  background: transparent;
  color: #fff;
}
.pix-btn.btn-light.btn-outline:hover {
  color: #f97bc5;
  background: #fff;
}
.pix-btn.btn-three {
  background: #fd4d5c;
  border: 1px solid #fd4d5c;
  box-shadow: none;
}
.pix-btn.btn-three:hover {
  background: transparent;
  color: #fd4d5c;
}
.pix-btn.btn-round {
  border-radius: 4px;
}
.pix-btn.btn-four {
  background: #12bf7c;
  border: 1px solid #12bf7c;
  box-shadow: none;
}
.pix-btn.btn-four:hover {
  background: transparent;
  color: #12bf7c;
}
.pix-btn.btn-round {
  border-radius: 4px;
}

.btn-underline {
  color: #797687;
  font-size: 15px;
  position: relative;
  padding-bottom: 3px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 15px;
  max-width: 140px;
  margin: 0 auto;
}
.btn-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: #797687;
  transition: all 0.3s ease-in-out;
}
.btn-underline i {
  display: inline-block;
  margin-left: 10px;
}
.btn-underline:hover {
  color: #ffc107;
}
.btn-underline:hover:before {
  background: #ffc107;
}

.play-btn {
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
}
.play-btn i {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  color: #ffc107;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 30px;
  text-shadow: 0px 10px 10px rgba(176, 70, 70, 0.4);
}
.play-btn:hover {
  color: #fff;
}
.play-btn.large i {
  height: 80px;
  width: 80px;
  line-height: 82px;
  color: #f97bc5;
  font-size: 40px;
}
.play-btn.play-btn-outline {
  background: transparent;
  color: #12bf7c;
}
.play-btn.play-btn-outline i {
  border: 2px solid #12bf7c;
  color: #12bf7c;
  text-shadow: 0px 6px 10px rgba(11, 176, 112, 0.4);
  height: 52px;
  width: 52px;
  line-height: 49px;
}
.play-btn-two {
  position: relative;
}
.play-btn-two i:before {
  position: relative;
  z-index: 222;
}
.play-btn-two i:after {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  background: #e6e7eb;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.app-btn {
  padding: 10px 20px;
  border-radius: 30px;
  color: #7052fb;
  display: inline-block;
  font-size: 18px;
  border: 2px solid #7052fb;
}
.app-btn i {
  color: #7052fb;
  font-size: 20px;
  vertical-align: -2px;
  margin-right: 4px;
  transition: all 0.3s ease-in-out;
}
.app-btn:hover {
  color: #fff;
  background: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
}
.app-btn:hover i {
  color: #fff;
}
.app-btn.btn-active {
  background: transparent;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
  background: #7052fb;
}
.app-btn.btn-active i {
  color: #fff;
}
.app-btn.btn-active:hover {
  background: transparent;
  color: #7052fb;
  box-shadow: none;
}
.app-btn.btn-active:hover i {
  color: #7052fb;
}

.app-btn-two {
  background: #1a133b;
  color: #fff;
  display: inline-flex;
  padding: 10px 25px;
  font-size: 18px;
  border-radius: 6px;
  align-items: center;
}
.app-btn-two.btn-light {
  background: #f3f3f4;
  color: #1a133b;
}
.app-btn-two.btn-light .top-text {
  color: #76747f;
}
.app-btn-two span {
  display: block;
}
.app-btn-two i {
  margin-right: 15px;
  font-size: 28px;
}
.app-btn-two .btn-text {
  font-weight: 500;
  line-height: 20px;
}
.app-btn-two .btn-text .text-top {
  font-size: 14px;
  font-weight: 400;
}
.app-btn-two:hover {
  background: #12bf7c;
  color: #fff;
}

/*--------------------------------------------------------------
  ##  List
  --------------------------------------------------------------*/
.list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-items li {
  font-size: 16px;
  line-height: 36px;
  font-weight: 300;
  color: #2b2350;
  padding-left: 36px;
  position: relative;
}
.list-items li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  top: 0;
  color: #ffc107;
}
.list-items.color-two li:before {
  color: #7052fb;
}
.list-items.list-with-icon li:before {
  display: none;
}
.list-items.list-with-icon li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #7052fb;
}
.list-items.list-icon-arrow li {
  padding-left: 25px;
}
.list-items.list-icon-arrow li:before {
  content: "$";
  color: #12bf7c;
}

/*--------------------------------------------------------------
  ##  Froms
  --------------------------------------------------------------*/
input,
textarea,
select {
  background: #fdfafa;
  padding: 15px 30px;
  border: 1px solid #efe7e7;
  margin-bottom: 30px;
  border-radius: 30px;
}
input:focus,
textarea:focus,
select:focus {
  background: #fff;
  box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}

textarea {
  height: 200px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  background-image: none;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select */
.pix-select {
  position: relative;
  display: flex;
}

select {
  flex: 1;
  color: #000;
  cursor: pointer;
}

/* Arrow */
.pix-select:after {
  content: "3";
  font-family: eleganticons;
  position: absolute;
  top: 19%;
  right: 0;
  padding-right: 15px;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
  font-size: 24px;
}

/* Transition */
.pix-select:hover:after {
  color: #ffc107;
}

/*--------------------------------------------------------------
##  Animation
--------------------------------------------------------------*/
@keyframes zoom {
  from {
    -webkit-transform: scale(1) translate(0px);
  }
  to {
    -webkit-transform: scale(1.03) translate(0px);
  }
}
@keyframes zoommd {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes zoomin {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
}
@keyframes movexy {
  0% {
    -webkit-transform: translateX(-10px) scale(0.9);
    transform: translateX(-10px) scale(0.9);
  }
  100% {
    -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}
@keyframes wave {
  0% {
    transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}
@keyframes pixFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pixFadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes pixFadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes pixFadeLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes pixFadeRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes pixZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pixBounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
.pixFade {
  animation-name: pixFade;
}

.pixFadeUp {
  animation-name: pixFadeUp;
}

.pixFadeDown {
  animation-name: pixFadeDown;
}

.pixFadeLeft {
  animation-name: pixFadeLeft;
}

.pixFadeRight {
  animation-name: pixFadeRight;
}

.pixZoomIn {
  animation-name: pixZoomIn;
}

.pixBounceIn {
  animation-name: pixBounceIn;
}

.zoomIn {
  animation-name: zoomin;
}

@keyframes jumping {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes jump {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(5px);
  }
}
@keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes moveclouds {
  0% {
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }
  50% {
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    width: 82px;
    height: 82px;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    height: 150px;
    width: 150px;
    opacity: 0;
  }
}
@keyframes animationFramesLeft {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesRight {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, 1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes wave {
  0% {
    transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
  }
}
@keyframes waveRote {
  0% {
    transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}
@keyframes animateRight {
  49% {
    transform: translateX(100%);
  }
  50% {
    opacity: 0;
    transform: translateX(-100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
@keyframes animationFramesThree {
  0% {
    transform: translate(165px, -179px);
  }
  100% {
    transform: translate(-346px, 617px);
  }
}
@keyframes animationFramesFour {
  0% {
    transform: translate(-300px, 151px) rotate(0deg);
  }
  100% {
    transform: translate(251px, -200px) rotate(180deg);
  }
}
@keyframes animationFramesFive {
  0% {
    transform: translate(61px, -99px) rotate(0deg);
  }
  21% {
    transform: translate(4px, -190px) rotate(38deg);
  }
  41% {
    transform: translate(-139px, -200px) rotate(74deg);
  }
  60% {
    transform: translate(-263px, -164px) rotate(108deg);
  }
  80% {
    transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
    transform: translate(-1px, 0px) rotate(180deg);
  }
}
/*--------------------------------------------------------------
##  Modal
--------------------------------------------------------------*/
.modal .modal-header .close {
  background: transparent;
  border: 0;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal .modal-header .close span {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.page-loader {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.page-loader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-loader svg {
  display: none;
}

.blobs {
  filter: url(#goo);
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 70px;
  transform-style: preserve-3d;
}
.blobs .blob-center {
  transform-style: preserve-3d;
  position: absolute;
  background: #ffc107;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform-origin: left top;
  transform: scale(0.9) translate(-50%, -50%);
  animation: blob-grow linear 3.4s infinite;
  border-radius: 50%;
  box-shadow: 0 -10px 40px -5px #ffc107;
}

.blob {
  position: absolute;
  background: #ffc107;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: blobs ease-out 3.4s infinite;
  transform: scale(0.9) translate(-50%, -50%);
  transform-origin: center top;
  opacity: 0;
}
.blob:nth-child(1) {
  animation-delay: 0.2s;
}
.blob:nth-child(2) {
  animation-delay: 0.4s;
}
.blob:nth-child(3) {
  animation-delay: 0.6s;
}
.blob:nth-child(4) {
  animation-delay: 0.8s;
}
.blob:nth-child(5) {
  animation-delay: 1s;
}

@keyframes blobs {
  0% {
    opacity: 0;
    transform: scale(0) translate(calc(-330px - 50%), -50%);
  }
  1% {
    opacity: 1;
  }
  35%, 65% {
    opacity: 1;
    transform: scale(0.9) translate(-50%, -50%);
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0) translate(calc(330px - 50%), -50%);
  }
}
@keyframes blob-grow {
  0%, 39% {
    transform: scale(0) translate(-50%, -50%);
  }
  40%, 42% {
    transform: scale(1, 0.9) translate(-50%, -50%);
  }
  43%, 44% {
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }
  45%, 46% {
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }
  47%, 48% {
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }
  52% {
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }
  54% {
    transform: scale(1.7, 1.6) translate(-50%, -50%);
  }
  58% {
    transform: scale(1.8, 1.7) translate(-50%, -50%);
  }
  68%, 70% {
    transform: scale(1.7, 1.5) translate(-50%, -50%);
  }
  78% {
    transform: scale(1.6, 1.4) translate(-50%, -50%);
  }
  80%, 81% {
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }
  82%, 83% {
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }
  84%, 85% {
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }
  86%, 87% {
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }
  90%, 91% {
    transform: scale(1, 0.9) translate(-50%, -50%);
  }
  92%, 100% {
    transform: scale(0) translate(-50%, -50%);
  }
}
.site-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999999999;
  transition: all 0.3s ease-in-out;
}
.site-header .header-inner {
  position: relative;
}
.site-header .header-inner .site-logo a {
  display: block;
  max-width: 150px;
}
.site-header .header-inner .site-logo a img {
  width: 100%;
}
.site-header .header-inner .site-logo a .sticky-logo {
  display: none;
}
.site-header .header-inner .site-nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.site-header .header-inner .site-nav .menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.site-header .header-inner .site-nav .menu-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.site-header .header-inner .site-nav .nav-right {
  margin-left: 50px;
}
.site-header .header-inner .site-nav .nav-right .nav-btn {
  background: #fff;
  box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
  color: #ffc107;
  display: inline-block;
  padding: 7px 37px;
  font-weight: 600;
  border-radius: 15px;
  font-size: 14px;
  border: 2px solid transparent;
}
.site-header .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #ffc107;
  color: #fff;
}
.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  padding: 6px 37px;
}
.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn:hover {
  background: #fff;
  color: #7052fb;
  border-color: #fff;
}
.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  padding: 9px 37px;
}
.site-header .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two:hover {
  background: #fff;
  color: #7052fb;
}
.site-header .header-inner .site-nav.nav-two .site-main-menu li .sub-menu {
  min-width: 300px !important;
}
.site-header .header-inner .site-nav.nav-two .site-main-menu li .sub-menu li a:hover, .site-header .header-inner .site-nav.nav-two .site-main-menu li .sub-menu li a.current_page {
  color: #7052fb;
}
.site-header .site-mobile-logo {
  display: none;
}
.site-header .site-main-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.site-header .site-main-menu li {
  margin: 0 23px;
  position: relative;
  padding: 36px 0;
  transition: all 0.3s ease-in-out;
}
.site-header .site-main-menu li:last-child {
  margin-right: 0;
}
.site-header .site-main-menu li > a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}
.site-header .site-main-menu li > a:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 1px;
  background: #fff;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  left: 0;
}
.site-header .site-main-menu li > a:hover:after, .site-header .site-main-menu li > a.current_page:after {
  width: 100%;
  opacity: 1;
}
.site-header .site-main-menu li.menu-item-has-children:after {
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  font-family: eleganticons;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.site-header .site-main-menu li.menu-item-has-children:hover:after {
  color: #ffc107;
}
.site-header .site-main-menu li .sub-menu {
  display: block;
  margin: 0;
  padding: 23px 30px;
  list-style: none;
  background: #fff;
  box-shadow: 0px 0px 36px 4px rgba(79, 35, 35, 0.1);
  position: absolute;
  top: 110%;
  left: 0;
  min-width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
  border-radius: 4px;
}
.site-header .site-main-menu li .sub-menu li {
  display: block;
  margin: 0;
  padding: 0;
}
.site-header .site-main-menu li .sub-menu li.menu-item-has-children:after {
  content: "5";
  right: 0px;
  color: #333;
}
.site-header .site-main-menu li .sub-menu li.menu-item-has-children .sub-menu {
  left: 105%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.site-header .site-main-menu li .sub-menu li.menu-item-has-children:hover .sub-menu {
  top: -23px;
  visibility: visible;
  opacity: 1;
}
.site-header .site-main-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
  color: #797687;
  font-size: 14px;
  font-weight: 300;
}
.site-header .site-main-menu li .sub-menu li a:after {
  display: none;
}
.site-header .site-main-menu li .sub-menu li a:hover, .site-header .site-main-menu li .sub-menu li a.current_page {
  color: #ffc107;
}
.site-header .site-main-menu li .sub-menu li:last-child a {
  border-bottom: 0;
}
.site-header .site-main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.site-header .nav-dark .site-main-menu li a {
  color: #2b2350;
}
.site-header .nav-dark .site-main-menu li a:after {
  display: none;
}
.site-header .nav-dark .site-main-menu li a:hover, .site-header .nav-dark .site-main-menu li a.current_page {
  color: #ffc107;
}
.site-header .nav-dark .site-main-menu li.menu-item-has-children:after {
  color: #2b2350;
}
.site-header.header-five .header-inner .site-nav .menu-wrapper {
  justify-content: space-between;
  position: relative;
  width: 80%;
}
.site-header.header-five .header-inner .site-nav .nav-right .nav-btn {
  background: transparent;
  color: #fff;
  box-shadow: none;
  border: 2px solid rgba(255, 255, 255, 0.302);
  border-radius: 4px;
  padding: 7px 24px;
  font-size: 16px;
}
.site-header.header-five .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #fff;
  border-color: #fff;
  color: #7052fb;
}
.site-header.header-five .site-main-menu li a {
  color: #2b2350;
}
.site-header.header-five .site-main-menu li a:after {
  display: none;
}
.site-header.header-five .site-main-menu li a:hover, .site-header.header-five .site-main-menu li a.current_page {
  color: #7052fb;
}
.site-header.header-five .site-main-menu li.menu-item-has-children:after {
  color: #2b2350;
}
.site-header.header-five .site-main-menu li .sub-menu li a:hover, .site-header.header-five .site-main-menu li .sub-menu li a.current_page {
  color: #7052fb;
}
.site-header.header-six .header-inner .site-nav {
  justify-content: space-between;
}
.site-header.header-six .header-inner .site-nav .nav-right .nav-btn {
  background: transparent;
  border: 2px solid #efbbd9;
  color: #f97bc5;
  box-shadow: none;
}
.site-header.header-six .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #f97bc5;
  color: #fff;
  border-color: #f97bc5;
}
.site-header.header-six .header-inner .site-nav .nav-right .login-btn {
  color: #646479;
  margin-right: 15px;
  font-weight: 500;
}
.site-header.header-six .header-inner .site-nav .nav-right .login-btn i {
  margin-right: 5px;
}
.site-header.header-six .header-inner .site-nav .nav-right .login-btn:hover {
  color: #f97bc5;
}
.site-header.header-six .site-main-menu li > a {
  color: #2b2350;
}
.site-header.header-six .site-main-menu li > a:hover {
  color: #f97bc5;
}
.site-header.header-six .site-main-menu li .sub-menu li a:hover, .site-header.header-six .site-main-menu li .sub-menu li a.current_page {
  color: #f97bc5;
}
.site-header.header-seven .header-inner .site-nav .menu-wrapper {
  justify-content: space-around;
  position: relative;
  width: 80%;
}
.site-header.header-seven .header-inner .site-nav .nav-right {
  margin-left: 0;
}
.site-header.header-seven .header-inner .site-nav .nav-right .nav-btn {
  background: transparent;
  color: #fff;
  box-shadow: none;
  border: 2px solid rgba(255, 255, 255, 0.302);
  border-radius: 4px;
  padding: 7px 24px;
  font-size: 16px;
}
.site-header.header-seven .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #fff;
  border-color: #fff;
  color: #fd4d5c;
}
.site-header.header-seven .site-main-menu li a {
  color: #fff;
}
.site-header.header-seven .site-main-menu li a:after {
  display: none;
}
.site-header.header-seven .site-main-menu li a:hover, .site-header.header-seven .site-main-menu li a.current_page {
  color: #fd4d5c;
}
.site-header.header-seven .site-main-menu li.menu-item-has-children:after {
  color: #2b2350;
}
.site-header.header-seven .site-main-menu li .sub-menu li a:hover, .site-header.header-seven .site-main-menu li .sub-menu li a.current_page {
  color: #fd4d5c;
}
.site-header.header-seven.pix-header-fixed .site-main-menu li a {
  color: #333;
}
.site-header.header-seven.pix-header-fixed .site-main-menu li a:hover {
  color: #fd4d5c;
}
.site-header.header-seven.pix-header-fixed .site-main-menu li.menu-item-has-children:after {
  color: #2b2350;
}
.site-header.header-seven.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn {
  border-color: #fd4d5c;
  color: #fd4d5c;
}
.site-header.header-seven.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #fd4d5c;
  color: #fff;
}
.site-header.header-eight .toggle-menu .bar {
  background: #0b0b0b;
}
.site-header.header-eight .header-inner .site-nav {
  justify-content: space-between;
}
.site-header.header-eight .header-inner .site-nav .nav-right .nav-btn {
  background: #12bf7c;
  border: 2px solid #12bf7c;
  color: #fff;
  box-shadow: none;
  border-radius: 4px;
  box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
}
.site-header.header-eight .header-inner .site-nav .nav-right .nav-btn:hover {
  background: transparent;
  color: #12bf7c;
  box-shadow: none;
}
.site-header.header-eight .site-main-menu li > a {
  color: #2b2350;
}
.site-header.header-eight .site-main-menu li > a:hover {
  color: #12bf7c;
}
.site-header.header-eight .site-main-menu li > a:hover:after {
  background: #12bf7c;
}
.site-header.header-eight .site-main-menu li .sub-menu li a:hover, .site-header.header-eight .site-main-menu li .sub-menu li a.current_page {
  color: #12bf7c;
}

.logo-sticky {
  display: none;
}

.pix-header-fixed {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999999;
  box-shadow: 0px 10px 20px 0px rgba(79, 35, 35, 0.08);
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  background: #fff;
}
.pix-header-fixed .header-inner .site-logo {
  padding: 18px 0;
}
.pix-header-fixed .header-inner .site-logo .logo-sticky {
  display: block;
}
.pix-header-fixed .header-inner .site-logo .logo-main {
  display: none;
}
.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn {
  background: transparent;
  color: #ffc107;
  box-shadow: none;
  border-color: #ffc107;
}
.pix-header-fixed .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #ffc107;
  color: #fff;
}
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn,
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two {
  border-color: #7052fb;
  color: #7052fb;
}
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn:hover,
.pix-header-fixed .header-inner .site-nav.nav-two .nav-right .nav-btn.style-two:hover {
  background: #7052fb;
  color: #fff;
  border-color: #7052fb;
}
.pix-header-fixed .header-inner .site-nav.nav-two .site-main-menu li a:hover, .pix-header-fixed .header-inner .site-nav.nav-two .site-main-menu li a.current_page {
  color: #7052fb;
}
.pix-header-fixed .site-main-menu li {
  padding: 28px 0;
}
.pix-header-fixed .site-main-menu li.menu-item-has-children:after {
  color: #333;
}
.pix-header-fixed .site-main-menu li a {
  color: #333;
}
.pix-header-fixed .site-main-menu li a:after {
  display: none;
}
.pix-header-fixed .site-main-menu li a:hover, .pix-header-fixed .site-main-menu li a.current_page {
  color: #ffc107;
}
.pix-header-fixed .site-main-menu li a:hover:before, .pix-header-fixed .site-main-menu li a:hover:after, .pix-header-fixed .site-main-menu li a.current_page:before, .pix-header-fixed .site-main-menu li a.current_page:after {
  background: #ffc107;
}
.pix-header-fixed .site-logo .main-logo {
  display: none;
}
.pix-header-fixed .site-logo .sticky-logo {
  display: block;
}
.pix-header-fixed.header-five .header-inner .site-nav .nav-right .nav-btn {
  color: #7052fb;
  border: 2px solid #7052fb;
}
.pix-header-fixed.header-five .header-inner .site-nav .nav-right .nav-btn:hover {
  background: #7052fb;
  border-color: #7052fb;
  color: #fff;
}

.mask-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3333;
  top: 0;
  left: 0;
}

.close-menu {
  display: none;
}

@media (max-width: 991px) {
  .toggle-menu {
    position: absolute;
    left: 0;
    width: 26px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 24px;
  }
  .toggle-menu .bar {
    width: 18px;
    height: 2px;
    display: block;
    float: left;
    margin: 3px auto;
    transition: all 0.3s ease-in-out;
    background: #0b0b0b;
  }
  .toggle-menu .bar:nth-child(2) {
    width: 24px;
  }
  .site-header .header-inner {
    text-align: center;
    padding: 15px 0;
  }
  .site-header .header-inner .site-nav .nav-right {
    margin-left: 20px;
  }
  .site-header .header-inner .site-nav .nav-right .nav-btn {
    padding: 8px 22px;
  }
  .site-header .site-mobile-logo {
    display: block;
  }
  .site-header .site-main-menu li {
    margin: 0 15px;
  }
  .site-header .site-logo {
    display: none;
  }
  .site-header.header-two.toggle-light .toggle-menu .bar {
    background: #fff;
  }
  .site-header.header-five .toggle-menu .bar, .site-header.header-six .toggle-menu .bar {
    background: #333;
  }
  .site-header .site-nav {
    position: fixed;
    width: 320px !important;
    height: 100vh;
    background: #fff;
    top: 0;
    left: -100%;
    display: block !important;
    transition: all 0.5s ease-in-out;
    overflow: scroll;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  }
  .site-header .site-nav .site-main-menu {
    display: block;
    width: 100%;
    padding-bottom: 50px;
    text-align: left;
  }
  .site-header .site-nav .site-main-menu li {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #f1f2f3;
  }
  .site-header .site-nav .site-main-menu li:first-child {
    border-top: 1px solid #f1f2f3;
  }
  .site-header .site-nav .site-main-menu li a {
    display: block;
    color: #888888;
    padding: 6px 35px;
    font-weight: 400;
  }
  .site-header .site-nav .site-main-menu li a:after {
    display: none;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children {
    position: relative;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children:after {
    display: none;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children a:hover, .site-header .site-nav .site-main-menu li.menu-item-has-children:focus {
    color: #ffc107;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children i {
    position: absolute;
    top: 6px;
    right: 20px;
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 222;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu {
    position: static;
    box-shadow: none;
    opacity: 1;
    width: auto;
    visibility: visible;
    background: transparent;
    padding: 0;
    display: none;
    transition: none;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li {
    padding: 0;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li:first-child {
    border-top: 1px solid #f1f2f3;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li a {
    color: #888888;
    font-weight: 400;
    padding: 5px 35px 5px 45px;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu {
    opacity: 1;
    visibility: visible;
    position: relative;
    left: 0;
    top: auto;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu li a {
    padding-left: 60px;
  }
  .site-header .site-nav .site-main-menu li.menu-item-has-children .sub-menu li.menu-item-has-children:after {
    content: "3";
    color: #fff;
  }
  .site-header .site-nav .nav-right {
    display: none;
  }
  .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:hover, .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:focus {
    color: #7052fb;
  }
  .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:hover:after, .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children:focus:after {
    color: #7052fb;
  }
  .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children a:hover, .site-header .site-nav.nav-two .site-main-menu li.menu-item-has-children a:focus {
    color: #7052fb;
  }
  .site-header.header-two .toggle-menu .bar {
    background: #2b2350;
  }
  .header-inner .site-mobile-logo img {
    max-height: 30px;
    transition: all 0.3s ease-in-out;
  }
  .pix-header-fixed .toggle-menu {
    top: 30px;
  }
  .pix-header-fixed .toggle-menu .bar {
    background: #333;
  }
  .pix-header-fixed .site-main-menu li a {
    color: #fff;
  }
  .pix-header-fixed .site-main-menu li.menu-item-has-children:after {
    color: #fff;
  }
  .pix-header-fixed .header-inner .site-logo {
    padding: 12px 0;
  }
  .pix-header-fixed.header-two.toggle-light .toggle-menu .bar {
    background: #000;
  }
  .sidebar-open .site-header .site-nav {
    left: 0;
  }
  .sidebar-open .menu-wrapper:before {
    opacity: 1;
    visibility: visible;
  }
  .close-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 0;
    text-align: right;
    width: 100%;
    left: 0;
    padding: 2px;
    padding: 30px 20px;
  }
  .close-menu i {
    font-size: 36px;
    color: #2b2350;
  }
  .close-menu span {
    font-size: 15px;
  }
  .header-dark .toggle-menu .bar {
    background: #2b2350;
  }
}
@media (max-width: 420px) {
  .site-header .site-nav {
    width: 100% !important;
  }
}
.banner {
  position: relative;
}
.banner .overlay-bg {
  position: absolute;
  right: 0;
  top: 0;
}
.banner .overlay-bg img {
  max-width: 100%;
  height: auto;
}
.banner .banner-content-wrap {
  position: relative;
  transform: translateY(42%);
  z-index: 2;
}
.banner .banner-content .banner-title {
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}
.banner .banner-content .banner-title span {
  font-weight: 800;
}
.banner .banner-content .banner-title span.big {
  font-size: 40px;
  letter-spacing: 2px;
}
.banner .banner-content .banner-title span.last {
  font-weight: 400;
  font-size: 30px;
  padding-top: 3px;
  display: inline-block;
}
.banner .banner-content .banner-subtitle {
  font-size: 14px;
  color: #fff;
  margin-top: 15px;
  text-align: center;
}
.banner .banner-content .hand {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #635c5c;
}
.banner .banner-content .description {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  margin-bottom: 47px;
}
.banner .banner-content .banner-btn {
  background: #fff;
  color: #383838;
  padding: 10px 40px;
  border: 1px solid #fff;
  border-radius: 15px;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  font-weight: bold;
  letter-spacing: 1px;
}
.banner .banner-content .banner-btn:hover {
  color: #ffc107;
  box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
}
.banner.banner-one {
  height: 640px;
  background-image: linear-gradient(100deg, #222222 0%, #000000 100%);
  position: relative;
  overflow-x: hidden;
}
.banner.banner-one .circle-shape {
  position: absolute;
  top: -20%;
  left: -13%;
}
.banner.banner-one .promo-mockup {
  margin-left: -120px;
}
.banner.banner-one .promo-mockup img {
  max-width: 920px;
  animation: zoom 4s infinite ease;
  animation-direction: alternate;
  transform: scale(1) translate(0px);
}
.banner.banner-one .bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.banner.banner-two {
  height: 1028px;
  overflow: hidden;
  background-image: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);
}
.banner.banner-two .vector-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1028px;
}
.banner.banner-two .vector-bg img {
  max-width: unset;
}
.banner.banner-two .banner-content-wrap {
  transform: translateY(37%);
}
.banner.banner-two .banner-content .banner-title {
  color: #2b2350;
}
.banner.banner-two .banner-content .description {
  color: #2b2350;
}
.banner.banner-two .banner-content .banner-btn.color-two {
  background: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
  border: 1px solid transparent;
}
.banner.banner-two .banner-content .banner-btn:hover {
  box-shadow: none;
  color: #fff;
}
.banner.banner-two .promo-mockup img {
  max-width: 850px;
}
.banner.banner-three {
  background-image: linear-gradient(100deg, #ffc107 0%, #ffb507 100%);
  height: 950px;
  min-height: 700px;
}
.banner.banner-three .banner-content-wrap-two {
  padding-top: 185px;
}
.banner.banner-three .banner-content {
  margin-bottom: 100px;
  position: relative;
  z-index: 22;
}
.banner.banner-three .banner-content .banner-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 800;
  margin-bottom: 20px;
}
.banner.banner-three .banner-content .description {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  margin-bottom: 47px;
}
.banner.banner-three .banner-content .banner-btn {
  color: #fff;
  padding: 8px 47px;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}
.banner.banner-three .banner-content .banner-btn.btn-fill {
  color: #ffc107;
}
.banner.banner-three .banner-content .banner-btn:hover {
  background: #fff;
  color: #ffc107;
  box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
}
.banner.banner-three .play-btn {
  margin-left: 20px;
  font-weight: 500;
}
.banner.banner-three .circle-shape,
.banner.banner-three .shape {
  position: absolute;
  z-index: 0;
  top: 0;
}
.banner.banner-three .circle-shape {
  left: 0;
}
.banner.banner-three .shape {
  right: 0;
}
.banner.banner-three .promo-mockup {
  position: relative;
  max-width: 970px;
  margin: 0 auto;
}
.banner.banner-three .promo-mockup img {
  box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
  border-radius: 10px;
}
.banner.banner-three .promo-mockup .shape-shadow {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
  height: 100px;
  max-width: 73%;
  position: absolute;
  bottom: -40px;
  width: 100%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.banner.banner-three .bg-shape-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.banner.banner-four {
  background-image: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);
  height: 1060px;
  padding-top: 290px;
}
.banner.banner-four .banner-content-wrap-two {
  padding-top: 185px;
}
.banner.banner-four .banner-content {
  margin-bottom: 100px;
  position: relative;
  z-index: 22;
}
.banner.banner-four .banner-content .banner-title {
  font-size: 60px;
  line-height: 76px;
  font-weight: 700;
  margin-bottom: 20px;
}
.banner.banner-four .banner-content .banner-title span {
  font-weight: 300;
}
.banner.banner-four .banner-content .description {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  margin-bottom: 47px;
}
.banner.banner-four .promo-mockup {
  position: relative;
  z-index: 2;
  margin-top: -80px;
  animation: jump 2s infinite;
}
.banner.banner-four .play-btn {
  font-weight: 400;
}
.banner.banner-four .play-btn i {
  color: #7052fb;
  text-shadow: none;
}
.banner.banner-four .bg-shape-inner {
  position: absolute;
  bottom: -2px;
}
.banner .banner-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner.banner-five {
  height: 930px;
  padding: 230px 0;
  background-image: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);
}
.banner.banner-five .banner-right-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner.banner-five .banner-right-shape img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner.banner-five .banner-content-wrap-five {
  position: relative;
  z-index: 33;
}
.banner.banner-five .banner-content-wrap-five .banner-content {
  margin-top: 98px;
}
.banner.banner-five .banner-content-wrap-five .banner-content .sub-title {
  font-size: 16px;
  text-transform: uppercase;
  color: #7052fb;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 15px;
}
.banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
  color: #322d49;
  font-size: 60px;
  line-height: 64px;
  font-weight: 500;
}
.banner.banner-five .banner-content-wrap-five .banner-content .description {
  color: #5e5b74;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 42px;
}
.banner.banner-five .banner-content-wrap-five .banner-content .banner-btn {
  background: #7052fb;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}
.banner.banner-five .banner-content-wrap-five .banner-content .banner-btn:hover {
  color: #7052fb;
  background: transparent;
  border-color: #7052fb;
  box-shadow: none;
}
.banner.banner-six {
  height: 950px;
  position: relative;
  padding-top: 225px;
  overflow: hidden;
}
.banner.banner-six .banne-content-wrapper-six {
  position: relative;
  z-index: 2;
}
.banner.banner-six .banne-content-wrapper-six .subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #f97bc5;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 13px;
}
.banner.banner-six .banne-content-wrapper-six .banner-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 20px;
}
.banner.banner-six .banne-content-wrapper-six p {
  margin-bottom: 40px;
}
.banner.banner-six:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 43%;
  height: 100%;
  right: 0;
  top: 0;
  background: #fdf5fe;
}
.banner.banner-six .banner-six-promo-image {
  position: relative;
  z-index: 2;
}
.banner.banner-six .banner-six-promo-image > img {
  border-radius: 10px;
  box-shadow: 0px 30px 40px 0px rgba(1, 50, 5, 0.14);
}
.banner.banner-six .banner-six-promo-image .dot-shape {
  display: block;
  position: absolute;
  bottom: -40px;
  left: -40px;
  z-index: -1;
  animation: wave 8s infinite linear;
}
.banner.banner-six .bottom-shape-coin {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner.banner-six .bottom-shape-coin li {
  position: absolute;
  bottom: 0;
}
.banner.banner-six .bottom-shape-coin li:nth-child(1) {
  left: -50px;
}
.banner.banner-six .bottom-shape-coin li:nth-child(2) {
  left: 60px;
}
.banner.banner-six .bottom-shape-coin li:nth-child(3) {
  left: 150px;
  bottom: 35px;
}
.banner.banner-six .bottom-shape-coin li:nth-child(4) {
  bottom: 20px;
  left: 37%;
}
.banner.banner-six .bottom-shape-coin li:nth-child(5) {
  top: 35%;
  left: 10%;
}
.banner.banner-six .particles-six span {
  display: block;
  position: absolute;
}
.banner.banner-six .particles-six span.angle {
  top: 140px;
  left: 90px;
  animation: animationFramesOne 20s infinite linear;
}
.banner.banner-six .particles-six span.dotsm {
  height: 15px;
  width: 15px;
  background: #43d355;
  left: 50%;
  top: 15%;
  border-radius: 50%;
  animation: animationFramesTwo 25s infinite linear;
}
.banner.banner-six .particles-six span.dotmd {
  height: 15px;
  width: 15px;
  background: #f8cc61;
  border-radius: 50%;
  top: 50%;
  left: 15%;
  animation: animationFramesRight 20s infinite linear;
}
.banner.banner-seven {
  height: 950px;
  background: #fff;
  padding-top: 230px;
}
@media (max-width: 991px) {
  .banner.banner-seven {
    padding-top: 150px;
    height: 800px;
  }
}
@media (max-width: 576px) {
  .banner.banner-seven {
    padding-bottom: 60px;
    height: auto;
  }
}
.banner.banner-seven .banner-content .banner-title {
  font-size: 44px;
  line-height: 56px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .banner.banner-seven .banner-content .banner-title {
    font-size: 36px;
    line-height: 46px;
  }
  .banner.banner-seven .banner-content .banner-title br {
    display: none;
  }
}
@media (max-width: 576px) {
  .banner.banner-seven .banner-content .banner-title {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 991px) {
  .banner.banner-seven .banner-content .description br {
    display: none;
  }
}
.banner.banner-seven .job-search-form-wrapper {
  max-width: 770px;
  margin: 0 auto 115px;
}
@media (max-width: 576px) {
  .banner.banner-seven .job-search-form-wrapper {
    margin: 0 auto 80px;
  }
}
.banner.banner-seven .job-search-form-wrapper .job-form-inner {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.banner.banner-seven .job-search-form-wrapper .job-form-inner input {
  border-radius: 0;
  margin: 0;
}
.banner.banner-seven .job-search-form-wrapper .job-form-inner button {
  min-width: 150px;
  border: 0;
  background: #fd4d5c;
  color: #fff;
}
.banner.banner-seven .promo-mockup {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 50px 70px 0px rgba(10, 1, 47, 0.12);
  max-width: 1030px;
  margin: 0 auto;
}
.banner.banner-eight {
  position: relative;
  height: 1050px;
  min-height: 100vh;
}
@media (max-width: 991px) {
  .banner.banner-eight {
    height: auto;
  }
}
.banner.banner-eight .banne-content-wrapper-eight {
  position: relative;
  z-index: 222;
}
@media (max-width: 991px) {
  .banner.banner-eight .banne-content-wrapper-eight {
    padding-top: 120px;
  }
}
.banner.banner-eight .banne-content-wrapper-eight .subtitle {
  font-size: 12px;
  color: #1a133b;
  background: #f0f2f7;
  padding: 5px 40px 5px 5px;
  border-radius: 10px;
  display: inline-block;
}
.banner.banner-eight .banne-content-wrapper-eight .subtitle span {
  background: #12bf7c;
  color: #fff;
  padding: 0 9px;
  border-radius: 20px;
  display: inline-block;
  line-height: 1.4;
  margin-right: 20px;
}
.banner.banner-eight .banne-content-wrapper-eight .banner-title {
  font-size: 70px;
  font-weight: 600;
  color: #1a133b;
  line-height: 1.2;
  margin-bottom: 12px;
}
@media (max-width: 1200px) {
  .banner.banner-eight .banne-content-wrapper-eight .banner-title {
    font-size: 55px;
  }
}
@media (max-width: 768px) {
  .banner.banner-eight .banne-content-wrapper-eight .banner-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .banner.banner-eight .banne-content-wrapper-eight .banner-title {
    font-size: 36px;
  }
}
.banner.banner-eight .banne-content-wrapper-eight .banner-title span {
  font-weight: 300;
}
.banner.banner-eight .banne-content-wrapper-eight p {
  margin-bottom: 40px;
}
.banner.banner-eight .banne-content-wrapper-eight .banner-button-container {
  justify-content: unset;
}
.banner.banner-eight .banne-content-wrapper-eight .banner-button-container .banner-btn {
  padding: 10px 38px;
  font-size: 16px;
  margin-right: 20px;
}
.banner.banner-eight .banner-six-promo-image {
  padding-top: 195px;
  position: relative;
}
@media (max-width: 991px) {
  .banner.banner-eight .banner-six-promo-image.text-right {
    text-align: center !important;
  }
}
@media (max-width: 1400px) {
  .banner.banner-eight .banner-six-promo-image {
    padding-top: 130px;
  }
}
@media (max-width: 991px) {
  .banner.banner-eight .banner-six-promo-image {
    padding-top: 50px;
    max-width: 480px;
    margin: 0 auto;
  }
}
.banner.banner-eight .banner-six-promo-image img {
  position: relative;
  z-index: 22;
  margin-right: -55px;
}
@media (max-width: 991px) {
  .banner.banner-eight .banner-six-promo-image img {
    margin-right: 0;
  }
}
.banner.banner-eight .banner-six-promo-image .banner-leaf {
  position: absolute;
  bottom: 120px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left,
.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-right {
  position: absolute;
  bottom: 0;
}
.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
  left: 65px;
}
@media (max-width: 1200px) {
  .banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
    left: -30px;
  }
}
@media (max-width: 991px) {
  .banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-left {
    left: -65px;
  }
}
.banner.banner-eight .banner-six-promo-image .banner-leaf .leaf-right {
  right: -15px;
  bottom: 65px;
}
.banner.banner-eight .banner-background-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.banner.banner-eight .banner-background-element .dot-shape,
.banner.banner-eight .banner-background-element .circle-bg,
.banner.banner-eight .banner-background-element .ball,
.banner.banner-eight .banner-background-element .triangle {
  position: absolute;
}
.banner.banner-eight .banner-background-element .ball {
  left: 100px;
  top: 190px;
}
@media (max-width: 1400px) {
  .banner.banner-eight .banner-background-element .ball {
    left: 20px;
  }
}
.banner.banner-eight .banner-background-element .triangle {
  bottom: 150px;
  left: 40%;
}
.banner.banner-eight .banner-background-element .dot-shape {
  right: 40px;
  top: 40px;
}
.banner.banner-eight .banner-background-element .circle-bg {
  height: 1100px;
  width: 1100px;
  background-image: linear-gradient(-120deg, rgb(253, 151, 32) 0%, rgb(18, 191, 124) 100%);
  opacity: 0.102;
  border-radius: 50%;
  top: -14%;
  right: -3.2%;
  z-index: -1;
}
@media (max-width: 1400px) {
  .banner.banner-eight .banner-background-element .circle-bg {
    height: 900px;
    width: 900px;
    top: -4%;
    right: -7%;
  }
}
@media (max-width: 991px) {
  .banner.banner-eight .banner-background-element .circle-bg {
    height: 600px;
    width: 600px;
    top: -2%;
    right: -3%;
  }
}

.animate-particle {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%;
  z-index: 22;
}
.animate-particle li {
  position: absolute;
}
.animate-particle li:nth-child(1) {
  left: 90px;
  top: 140px;
  animation: animationFramesRight 40s infinite linear;
}
.animate-particle li:nth-child(2) {
  left: 40%;
  bottom: 13%;
  animation: animationFramesRight 30s infinite linear;
}
.animate-particle li:nth-child(3) {
  right: 28%;
  top: 14%;
  animation: animationFramesRight 30s infinite linear;
}
.animate-particle li:nth-child(4) {
  right: 5%;
  top: 63%;
  animation: animationFramesLeft 20s infinite linear;
}
.animate-particle li:nth-child(5) {
  left: 50%;
  top: 25%;
  animation: animationFramesLeft 20s infinite linear;
}
.animate-particle li:nth-child(6) {
  right: -9%;
  top: 110px;
}
.animate-particle li.bubble {
  height: 10px;
  width: 10px;
  background: #a2e3f6;
  border-radius: 50%;
  left: 15%;
  top: 28%;
  animation: animationFramesLeft 30s infinite linear;
}

.left-circle-shape {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 52%;
  left: 0;
}
.left-circle-shape .circle-fill {
  height: 800px;
  width: 800px;
  background: #faf9ff;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: -18%;
  animation: wave 10s linear infinite;
}
.left-circle-shape .circle-border {
  height: 400px;
  width: 400px;
  display: block;
  border: 2px dashed #feebee;
  z-index: 22;
  position: absolute;
  border-radius: 50%;
  left: 7%;
  top: 9%;
  animation: waveRote 5s linear infinite;
}

.newsletter-form-banner {
  margin-bottom: 50px;
}
.newsletter-form-banner .newsletter-inner {
  position: relative;
  max-width: 475px;
}
.newsletter-form-banner .newsletter-inner input {
  width: 100%;
  margin: 0;
  height: 56px;
  border-radius: 30px;
  background: #fff;
  border: 0;
  outline: 0;
  padding: 10px 180px 10px 30px;
}
.newsletter-form-banner .newsletter-inner input:focus {
  box-shadow: none;
}
.newsletter-form-banner .newsletter-inner button {
  position: absolute;
  right: 3px;
  top: 4px;
  border: 0;
  background: #7052fb;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 30px;
}
.newsletter-form-banner .newsletter-inner button i {
  display: none;
}
.newsletter-form-banner .newsletter-inner button.clicked i {
  display: block;
}
.newsletter-form-banner .newsletter-inner button:hover {
  background: #5c42d2;
}

.animate-promo-mockup {
  position: relative;
  width: 830px;
  height: 600px;
  margin-left: -40px;
}
.animate-promo-mockup img {
  position: absolute;
}
.animate-promo-mockup img:nth-child(1) {
  left: 100px;
  bottom: 100px;
}
.animate-promo-mockup img:nth-child(2) {
  left: 0;
  bottom: 100px;
}
.animate-promo-mockup img:nth-child(3) {
  right: 0;
  bottom: 150px;
}
.animate-promo-mockup img:nth-child(4) {
  bottom: 15px;
  left: 80px;
}
.animate-promo-mockup img:nth-child(5) {
  bottom: 25px;
  left: 49%;
}
.animate-promo-mockup img:nth-child(6) {
  right: 176px;
  bottom: 0;
  animation: jump 2s infinite;
}
.animate-promo-mockup img:nth-child(7) {
  right: 114px;
  bottom: 33px;
}
.animate-promo-mockup img:nth-child(8) {
  left: 0;
  top: 0;
  animation: moveclouds 4s 1s linear infinite alternate;
}
.animate-promo-mockup img:nth-child(9) {
  left: 50%;
  top: 0;
  animation: moveclouds 4s 2s linear infinite alternate;
}
.animate-promo-mockup img:nth-child(10) {
  left: 90%;
  top: 0;
  animation: moveclouds 4s 3s linear infinite alternate;
}

.slider-nav {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 999;
}
.slider-nav .slider-prev,
.slider-nav .slider-next {
  height: 100px;
  width: 40px;
  text-align: center;
  line-height: 100px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.slider-nav .slider-prev i,
.slider-nav .slider-next i {
  font-size: 30px;
  color: #fff;
}
.slider-nav .slider-prev:hover,
.slider-nav .slider-next:hover {
  background: #ffc107;
}
.slider-nav .slider-next {
  right: 0;
}
.slider-nav .slider-prev {
  left: 0;
}

.swiper-pagination {
  margin-top: 25px;
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%);
}
.swiper-pagination .swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  margin-right: 3px;
}
.swiper-pagination .swiper-pagination-bullet:focus {
  outline: 0;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
.swiper-pagination.style-three {
  margin-top: 15px;
}
.swiper-pagination.style-three .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  background: #ababad;
  margin-right: 5px;
}
.swiper-pagination.style-three .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fd4d5c;
  opacity: 1;
}

.section-dark .gp-testimonial .gp-testimonial-author .author-details .testi-author-name {
  color: #fff;
}
.section-dark .gp-testimonial .gp-testimonial-author .author-details span {
  color: #b5b5b5;
}
.section-dark .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.5;
}

.scroll-circle {
  position: absolute;
  bottom: -48%;
  z-index: 1;
}

/* Animate element five*/
.animate-element-five {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 710px;
  height: 530px;
}
.animate-element-five li {
  position: absolute;
}
.animate-element-five li:nth-child(1) {
  right: 60px;
}
.animate-element-five li:nth-child(2) {
  left: 150px;
  top: 80px;
}
.animate-element-five li:nth-child(3) {
  right: 25px;
  top: 33%;
}
.animate-element-five li:nth-child(4) {
  right: 15px;
  bottom: 55px;
}
.animate-element-five li:nth-child(5) {
  left: 0;
  bottom: 0;
}
.animate-element-five li:nth-child(6) {
  bottom: -10px;
  left: 40%;
}
.animate-element-five li:nth-child(7) {
  bottom: 130px;
  left: 47%;
}
.animate-element-five li:nth-child(8) {
  bottom: 130px;
  left: 44%;
}
.animate-element-five li:nth-child(9) {
  right: 100px;
  bottom: -10px;
}

@media (max-width: 1600px) {
  .banner.banner-one .promo-mockup {
    margin-left: -50px;
  }
  .banner.banner-one .promo-mockup img {
    max-width: 800px;
  }
  .banner.banner-two .vector-bg {
    left: -15%;
  }
  .banner.banner-two .animate-promo-mockup {
    width: 550px;
    height: 530px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -160px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 62%;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: -10px;
    width: 230px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -60px;
    width: 99px;
  }
  .banner.banner-three .circle-shape {
    left: -20%;
  }
}
@media (max-width: 1440px) {
  .banner.banner-one .promo-mockup {
    margin-left: -50px;
  }
  .banner.banner-one .promo-mockup img {
    max-width: 700px;
  }
  .banner.banner-two {
    height: 890px;
  }
  .banner.banner-two .vector-bg {
    left: -14%;
    height: 890px;
  }
  .banner.banner-two .vector-bg img {
    height: 890px;
  }
  .banner.banner-two .banner-content-wrap {
    transform: translateY(50%);
  }
  .banner.banner-two .banner-content .banner-title {
    font-size: 50px;
    line-height: 60px;
  }
  .banner.banner-two .animate-promo-mockup {
    width: 480px;
    height: 470px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(1) {
    left: 85px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(2) {
    width: 85px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -135px;
    width: 61px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(4) {
    left: 65px;
    width: 150px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 62%;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: -5px;
    width: 210px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -45px;
    width: 90px;
  }
  .banner.banner-three .circle-shape {
    left: -35%;
  }
  .banner.banner-three .shape {
    right: -15%;
  }
  .animate-element-five {
    margin-left: -100px;
  }
}
@media (max-width: 1280px) {
  .banner.banner-one .banner-content .banner-title {
    font-size: 30px;
    line-height: 40px;
  }
  .banner.banner-one .banner-content p br {
    display: none;
  }
  .banner.banner-one .promo-mockup {
    margin-left: -90px;
  }
  .banner.banner-one .promo-mockup img {
    max-width: 100%;
  }
  .banner.banner-six .banne-content-wrapper-six .banner-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .banner.banner-two .banner-content .banner-title {
    font-size: 40px;
    line-height: 50px;
  }
  .banner.banner-two .banner-content p br {
    display: none;
  }
  .banner.banner-two .animate-promo-mockup {
    width: 380px;
    height: 400px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(1) {
    left: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(2) {
    width: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -100px;
    width: 50px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(4) {
    left: 55px;
    width: 125px;
    bottom: 30px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 55%;
    width: 40px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: -3px;
    width: 180px;
    bottom: 8px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -35px;
    width: 70px;
    bottom: 50px;
  }
  .banner.banner-three .shape {
    right: -25%;
  }
}
@media (max-width: 1200px) {
  .banner.banner-two {
    height: 840px;
  }
  .banner.banner-two .vector-bg {
    height: 840px;
    left: -26%;
  }
  .banner.banner-two .vector-bg img {
    height: 840px;
  }
  .banner.banner-four {
    height: 880px;
    padding-top: 240px;
  }
  .banner.banner-four .banner-content .banner-title {
    font-size: 50px;
    line-height: 65px;
  }
  .banner.banner-four .promo-mockup {
    margin-top: -30px;
  }
  .banner.banner-five {
    height: 700px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content {
    margin-top: 50px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
    font-size: 45px;
    line-height: 55px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content .description br {
    display: none;
  }
  .animate-element-five {
    margin: 0;
    width: 480px;
    height: 390px;
  }
  .animate-element-five li:nth-child(1) {
    width: 320px;
    right: 20px;
  }
  .animate-element-five li:nth-child(2) {
    left: 120px;
    top: 55px;
    height: 60px;
    width: 60px;
  }
  .animate-element-five li:nth-child(3) {
    right: 0;
    top: 30%;
    height: 60px;
    width: 60px;
  }
  .animate-element-five li:nth-child(4) {
    width: 70px;
    right: -10px;
  }
  .animate-element-five li:nth-child(5) {
    width: 230px;
  }
  .animate-element-five li:nth-child(6) {
    width: 90px;
    bottom: 15px;
    left: 41%;
  }
  .animate-element-five li:nth-child(7) {
    bottom: 110px;
    left: 47%;
  }
  .animate-element-five li:nth-child(8) {
    bottom: 108px;
    left: 43%;
  }
  .animate-element-five li:nth-child(9) {
    width: 140px;
    right: 55px;
    bottom: 10px;
  }
  .left-circle-shape .circle-fill {
    height: 550px;
    width: 550px;
  }
  .left-circle-shape .circle-border {
    height: 300px;
    width: 300px;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  .banner.banner-one {
    height: 700px;
  }
  .banner.banner-one .banner-content .banner-title {
    font-size: 25px;
    line-height: 35px;
  }
  .banner.banner-one .circle-shape {
    max-width: 500px;
  }
}
@media (max-width: 991px) {
  .banner.banner-one {
    height: 840px;
  }
  .banner.banner-one .banner-content-wrap {
    transform: translateY(15%);
  }
  .banner.banner-one .banner-content {
    margin-bottom: 50px;
  }
  .banner.banner-one .banner-content .banner-title {
    font-size: 25px;
    line-height: 35px;
  }
  .banner.banner-one .banner-content p br {
    display: none;
  }
  .banner.banner-one .promo-mockup {
    margin-left: -45px;
  }
  .banner.banner-one .circle-shape {
    max-width: 500px;
  }
  .banner.banner-two {
    height: 840px;
  }
  .banner.banner-two .vector-bg {
    height: 840px;
    left: -27%;
  }
  .banner.banner-two .vector-bg img {
    height: 840px;
  }
  .banner.banner-two .banner-content-wrap {
    transform: translateY(18%);
  }
  .banner.banner-two .banner-content .banner-title {
    font-size: 40px;
    line-height: 50px;
  }
  .banner.banner-two .banner-content p br {
    display: none;
  }
  .banner.banner-two .animate-promo-mockup {
    width: 380px;
    height: 380px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(1) {
    left: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(2) {
    width: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -100px;
    width: 50px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(4) {
    left: 55px;
    width: 125px;
    bottom: 30px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 55%;
    width: 40px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: -3px;
    width: 180px;
    bottom: 8px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -35px;
    width: 70px;
    bottom: 50px;
  }
  .banner.banner-three .promo-mockup img {
    box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
  }
  .banner.banner-three .promo-mockup .shape-shadow {
    box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
  }
  .banner.banner-three.banner-three .circle-shape {
    left: -50%;
  }
  .banner.banner-three.banner-three .shape {
    right: -40%;
  }
  .banner.banner-three .banner-content .banner-title {
    font-size: 45px;
    line-height: 55px;
  }
  .banner.banner-four {
    height: 740px;
    padding-top: 200px;
  }
  .banner.banner-four .banner-content .banner-title {
    font-size: 40px;
    line-height: 55px;
  }
  .banner.banner-four .banner-content p {
    font-size: 16px;
  }
  .banner.banner-four .banner-content p br {
    display: none;
  }
  .banner.banner-four .promo-mockup {
    margin-top: -30px;
  }
  .banner.banner-five {
    height: auto;
    padding: 100px 0 100px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content {
    max-width: 500px;
    margin: 0 auto 50px;
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    padding: 35px;
    border-radius: 10px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content .sub-titl {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content .banner-title {
    font-size: 34px;
    line-height: 44px;
  }
  .banner.banner-five .banner-content-wrap-five .banner-content .description {
    font-size: 14px;
  }
  .banner.banner-six {
    height: 650px;
    padding-top: 145px;
  }
  .banner.banner-six .banne-content-wrapper-six .banner-title {
    font-size: 30px;
    line-height: 40px;
  }
  .banner .animate-element-five {
    position: relative;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .banner.banner-one {
    height: 950px;
  }
  .banner.banner-one .banner-content-wrap {
    transform: translateY(20%);
  }
  .banner.banner-one .promo-mockup {
    margin-left: -45px;
  }
  .banner.banner-one .promo-mockup img {
    max-width: 100%;
  }
  .banner.banner-two {
    height: 840px;
  }
  .banner.banner-two .vector-bg {
    right: -120%;
  }
  .banner.banner-two .banner-content-wrap {
    transform: translateY(18%);
  }
  .banner.banner-two .animate-promo-mockup {
    width: 360px;
    height: 370px;
    margin-left: 0;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(1) {
    left: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(2) {
    width: 65px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -100px;
    width: 50px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(4) {
    left: 55px;
    width: 125px;
    bottom: 30px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 55%;
    width: 40px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: -3px;
    width: 170px;
    bottom: 13px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -35px;
    width: 67px;
    bottom: 50px;
  }
  .banner.banner-three {
    height: 750px;
  }
  .banner.banner-three .banner-content {
    margin-bottom: 50px;
  }
  .banner.banner-three .banner-content .banner-title {
    font-size: 34px;
    line-height: 44px;
  }
  .banner.banner-three .banner-content .description br {
    display: none;
  }
  .banner.banner-three .banner-content-wrap-two {
    padding-top: 130px;
  }
  .banner.banner-three .circle-shape {
    left: -85% !important;
  }
  .banner.banner-three .shape {
    right: -60% !important;
  }
  .banner.banner-four {
    height: auto;
    padding-top: 120px;
  }
  .banner.banner-four .banner-content .banner-title {
    font-size: 40px;
    line-height: 55px;
  }
  .banner.banner-four .banner-content p {
    font-size: 16px;
  }
  .banner.banner-four .banner-content p br {
    display: none;
  }
  .banner.banner-four .promo-mockup {
    margin-top: -30px;
  }
  .banner.banner-six {
    height: auto;
    padding-bottom: 80px;
  }
  .banner.banner-six .banne-content-wrapper-six {
    margin-bottom: 30px;
  }
}
@media (max-width: 640px) {
  .banner.banner-two .vector-bg {
    left: -45%;
  }
  .banner.banner-two .vector-bg {
    left: -52%;
  }
  .banner.banner-three .circle-shape {
    left: -95% !important;
  }
  .banner.banner-three .shape {
    right: -70% !important;
  }
}
@media (max-width: 576px) {
  .banner .banner-content .description {
    font-size: 16px;
  }
  .banner.banner-one {
    height: 840px;
  }
  .banner.banner-one .promo-mockup {
    margin-left: 0;
  }
  .banner.banner-one .banner-content .banner-title {
    font-size: 30px;
    line-height: 35px;
  }
  .banner.banner-two .vector-bg {
    left: -50%;
  }
  .banner.banner-three {
    height: 780px;
  }
  .banner.banner-three .circle-shape {
    left: -130% !important;
  }
  .banner.banner-three .shape {
    right: -90% !important;
  }
  .banner.banner-three .shape-shadow {
    display: none;
  }
  .banner.banner-three .promo-mockup img {
    max-width: 400px;
  }
}
@media (max-width: 480px) {
  .banner.banner-one {
    height: 770px;
  }
  .banner.banner-one .banner-content-wrap {
    transform: translateY(20%);
  }
  .banner.banner-one .promo-mockup img {
    max-width: 100%;
  }
  .banner.banner-two {
    height: 840px;
  }
  .banner.banner-two .vector-bg {
    left: -100%;
  }
  .banner.banner-two .banner-content-wrap {
    transform: translateY(18%);
  }
  .banner.banner-two .animate-promo-mockup {
    width: 300px;
    height: 325px;
    margin-left: 0;
    margin-top: 20px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(1) {
    left: 30px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(2) {
    width: 40px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(3) {
    right: -30px;
    width: 30px;
    bottom: 130px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(4) {
    left: 20px;
    width: 80px;
    bottom: 56px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(5) {
    left: 49%;
    width: 30px;
    bottom: 45px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(6) {
    right: 20px;
    width: 140px;
    bottom: 30px;
  }
  .banner.banner-two .animate-promo-mockup img:nth-child(7) {
    right: -5px;
    width: 50px;
    bottom: 70px;
  }
  .banner.banner-three {
    height: auto;
    padding-bottom: 40px;
  }
  .banner.banner-three .banner-content .banner-title {
    font-size: 30px;
    line-height: 40px;
  }
  .banner.banner-three .promo-mockup img {
    max-width: 100%;
  }
  .banner.banner-six .banne-content-wrapper-six .banner-title {
    font-size: 24px;
    line-height: 34px;
  }
  .left-circle-shape {
    top: 28%;
  }
  .animate-element-five {
    width: 300px;
    height: 280px;
  }
  .animate-element-five li:nth-child(1) {
    width: 250px;
  }
  .animate-element-five li:nth-child(2) {
    width: 45px;
    height: 45px;
    left: 15px;
    top: 45px;
  }
  .animate-element-five li:nth-child(3) {
    width: 45px;
    height: 45px;
  }
  .animate-element-five li:nth-child(4) {
    width: 55px;
    bottom: 18px;
  }
  .animate-element-five li:nth-child(5) {
    width: 160px;
    left: -30px;
    bottom: -10px;
  }
  .animate-element-five li:nth-child(6) {
    bottom: -6px;
    left: 41%;
    width: 70px;
  }
  .animate-element-five li:nth-child(7) {
    bottom: 65px;
    left: 49%;
    width: 50px;
  }
  .animate-element-five li:nth-child(8) {
    bottom: 62px;
    left: 43%;
    width: 28px;
  }
  .animate-element-five li:nth-child(9) {
    width: 70px;
    right: 36px;
    bottom: 0;
  }
  .left-circle-shape {
    left: -20%;
  }
  .left-circle-shape .circle-fill {
    height: 350px;
    width: 350px;
  }
  .left-circle-shape .circle-border {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 380px) {
  .banner .banner-button-container {
    display: block;
  }
  .banner .banner-button-container .play-btn {
    margin-top: 30px;
  }
  .banner.banner-five {
    overflow: hidden;
  }
  .newsletter-form-banner .newsletter-inner button {
    padding: 10px 20px;
  }
  .newsletter-form-banner .newsletter-inner input {
    padding: 10px 140px 10px 30px;
  }
}
.section-title {
  margin-bottom: 70px;
}
.section-title .sub-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffc107;
  letter-spacing: 3px;
}
.section-title .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.4;
}
.section-title .title span {
  color: #ffc107;
}
.section-title .title-two {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  max-width: 930px;
  margin: 0 auto;
}
.section-title .title-two span {
  color: #ffc107;
}
.section-title.style-two .title {
  font-weight: 500;
}
.section-title.style-two p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 28px;
}
.section-title.style-two .sub-title {
  margin: 0;
  line-height: 1.8;
}
.section-title.style-two.title-light .sub-title {
  letter-spacing: 5px;
  font-weight: 400;
}
.section-title.style-two.title-light .sub-title, .section-title.style-two.title-light .title {
  color: #fff;
}
.section-title.style-two.title-light p {
  color: #fff;
}
.section-title.style-three .sub-title {
  color: #f97bc5;
  letter-spacing: 5px;
  font-weight: 400;
  margin: 0;
  line-height: 1.8;
}
.section-title.style-three span {
  font-weight: 300;
  color: #2b2350;
}
.section-title.dark-title .sub-title,
.section-title.dark-title .title {
  color: #fff;
}
.section-title.color-two .sub-title {
  color: #7052fb;
}
.section-title.style-four .title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .section-title.style-four .title {
    font-size: 32px;
  }
}
.section-title.style-four .sub-title {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 3px;
  color: #7d7a8b;
  text-transform: uppercase;
  line-height: 1.4;
}
.section-title.style-five {
  margin-bottom: 55px;
}
.section-title.style-five .sub-title {
  color: #12bf7c;
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 500;
  margin-bottom: 13px;
}
.section-title.style-five .title {
  font-size: 40px;
  color: #1a133b;
  font-weight: 600;
}
@media (max-width: 991px) {
  .section-title.style-five .title {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .section-title.style-five .title {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .section-title {
    margin-bottom: 50px;
  }
  .section-title .title {
    font-size: 32px;
  }
  .section-title p br {
    display: none;
  }
  .section-title.style-two p {
    margin-top: 13px;
  }
  .section-title.style-two p br {
    display: none;
  }
  .section-title .title-two {
    font-size: 26px;
    line-height: 36px;
  }
}
@media (max-width: 768px) {
  .section-title .title {
    font-size: 28px;
  }
  .section-title .title br {
    display: none;
  }
}
.featured {
  padding-top: 32px;
}

.featured-seven {
  padding: 115px 0 50px;
}

.featured-eight {
  padding: 117px 0 90px;
}
.featured-eight .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.saaspik-icon-box-wrapper {
  margin-bottom: 30px;
}
.saaspik-icon-box-wrapper.style-one {
  margin-top: 16px;
}
.saaspik-icon-box-wrapper.style-one .saaspik-icon-box-icon {
  margin-bottom: 40px;
  height: 150px;
}
.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}
.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.saaspik-icon-box-wrapper.style-one .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #ffc107;
}
.saaspik-icon-box-wrapper .saaspik-icon-box-icon {
  margin-bottom: 32px;
}
.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}
.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #ffc107;
}
.saaspik-icon-box-wrapper .pixsass-icon-box-content p {
  margin: 0;
}
.saaspik-icon-box-wrapper.style-two {
  margin-bottom: 88px;
}
.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #ffc107;
}
.saaspik-icon-box-wrapper.style-three {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
  padding: 40px 50px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 22;
  align-items: center;
}
.saaspik-icon-box-wrapper.style-three .saaspik-icon-box-icon {
  width: 70px;
  margin-right: 30px;
  margin-bottom: 0;
}
.saaspik-icon-box-wrapper.style-three .pixsass-icon-box-content {
  flex: 1;
}
.saaspik-icon-box-wrapper.style-three .pixsass-icon-box-content p {
  margin: 0;
}
.saaspik-icon-box-wrapper.style-four {
  border-radius: 4px;
  border: 1px solid #ece4e4;
  padding: 60px 40px 32px;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}
.saaspik-icon-box-wrapper.style-four .layer {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: all 0.6s ease-in-out;
}
.saaspik-icon-box-wrapper.style-four .saaspik-icon-box-icon {
  margin-bottom: 44px;
  height: 80px;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 25px;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #ffc107;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content p {
  margin-bottom: 22px;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .more-btn {
  font-size: 35px;
  color: #bdb4b4;
  display: inline-block;
}
.saaspik-icon-box-wrapper.style-four .pixsass-icon-box-content .more-btn:hover {
  color: #ffc107;
}
.saaspik-icon-box-wrapper.style-four:hover {
  box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
}
.saaspik-icon-box-wrapper.style-four:hover .layer {
  top: 0;
}
.saaspik-icon-box-wrapper.style-five {
  box-shadow: 0px 30px 60px 0px rgba(52, 27, 159, 0.1);
  padding: 45px 40px;
  background: #fff;
}
.saaspik-icon-box-wrapper.style-five .saaspik-icon-box-icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border: 2px solid #7052fb;
  color: #7052fb;
  font-size: 22px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 42px;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .pixsass-icon-box-title {
  margin-bottom: 15px;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content p {
  margin-bottom: 35px;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn {
  color: #2b2350;
  font-weight: 500;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn i {
  vertical-align: middle;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-five .pixsass-icon-box-content .more-btn:hover i {
  padding-left: 3px;
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-five:hover .saaspik-icon-box-icon {
  box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
  background: #7052fb;
  color: #fff;
}
.saaspik-icon-box-wrapper.style-six {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.saaspik-icon-box-wrapper.style-six .saaspik-icon-box-icon {
  height: 70px;
  width: 70px;
  text-align: center;
  box-shadow: 0px 30px 40px 0px rgba(52, 27, 159, 0.1);
  padding: 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content {
  flex: 1;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .pixsass-icon-box-title {
  margin-bottom: 15px;
  font-weight: 400;
  margin: 0;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content p {
  margin-bottom: 35px;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn {
  color: #2b2350;
  font-weight: 500;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn i {
  vertical-align: middle;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-six .pixsass-icon-box-content .more-btn:hover i {
  padding-left: 3px;
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-seven {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 90px;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-icon {
  width: 60px;
  margin-right: 20px;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content {
  flex: 2;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title {
  font-size: 20px;
  color: #081e39;
  font-weight: 600;
  margin-bottom: 13px;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title a {
  color: #081e39;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .saaspik-icon-box-title a:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content p {
  color: #5e5b74;
  margin-bottom: 17px;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .read-more {
  color: #5e5b74;
  font-size: 16px;
  font-weight: 500;
}
.saaspik-icon-box-wrapper.style-seven .saaspik-icon-box-content .read-more:hover {
  color: #7052fb;
}
.saaspik-icon-box-wrapper.style-eight {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 30px 50px 0px rgba(64, 24, 47, 0.08);
  text-align: center;
  padding: 40px 35px;
}
.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content .pixsass-icon-box-title {
  margin-bottom: 15px;
}
.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #f97bc5;
}
.saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content p {
  margin-bottom: 35px;
}
.saaspik-icon-box-wrapper.style-nine {
  margin-bottom: 90px;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon {
  height: 70px;
  width: 70px;
  background: #fee4f4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-two {
  background: #fcf5e3;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-three {
  background: #efe9fe;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-four {
  background: #eaf7eb;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-five {
  background: #e4f8fd;
}
.saaspik-icon-box-wrapper.style-nine .saaspik-icon-box-icon.color-six {
  background: #ffeae7;
}
.saaspik-icon-box-wrapper.style-nine .pixsass-icon-box-content .pixsass-icon-box-title {
  margin-bottom: 10px;
}
.saaspik-icon-box-wrapper.style-nine .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #f97bc5;
}
.saaspik-icon-box-wrapper.style-ten {
  padding: 50px 40px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 20px 60px 0px rgba(1, 51, 32, 0.1);
  text-align: center;
}
.saaspik-icon-box-wrapper.style-ten .pixsass-icon-box-content .pixsass-icon-box-title {
  margin-bottom: 15px;
}
.saaspik-icon-box-wrapper.style-ten .pixsass-icon-box-content p {
  margin-bottom: 30px;
}

.featured-six {
  padding: 110px 0 104px;
}

.featured-five {
  padding: 70px 0;
}

.featured-eight .particale-top {
  width: 100%;
  height: 500px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
}
.featured-eight .particale-top li {
  position: absolute;
}
.featured-eight .particale-top li:nth-child(1) {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  left: 8%;
  top: 40%;
  background-color: rgba(255, 255, 255, 0.141);
  animation: animationFramesOne 20s infinite linear;
}
.featured-eight .particale-top li:nth-child(2) {
  left: 16%;
  top: 54%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 35px solid rgba(255, 255, 255, 0.141);
  animation: animationFramesLeft 20s infinite linear;
}
.featured-eight .particale-top li:nth-child(3) {
  left: 11%;
  bottom: 28px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid rgba(255, 255, 255, 0.141);
  animation: animationFramesTwo 20s infinite linear;
}
.featured-eight .particale-top li:nth-child(4) {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  right: 12%;
  top: 44%;
  background-color: rgba(255, 255, 255, 0.141);
  animation: animationFramesFive 20s infinite linear;
}
.featured-eight .particale-top li:nth-child(5) {
  right: 0;
  top: 33%;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 50px solid rgba(255, 255, 255, 0.141);
  animation: animationFramesLeft 20s infinite linear;
}
.featured-eight .particale-top li:nth-child(6) {
  right: 80px;
  top: 140px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid rgba(255, 255, 255, 0.141);
  animation: animationFramesOne 20s infinite linear;
}

.featured-tax {
  padding: 32px 0 22px;
}

.featured-two-service .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.featured-two-service .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #ffc107;
}

.featured-two {
  padding: 126px 0 28px;
}
.featured-two .section-title {
  margin-bottom: 80px;
}

.featured-three {
  padding: 116px 0 120px;
  background: #fffbe9;
  overflow: hidden;
}
.featured-three .scroll-circle {
  z-index: 1;
  left: -120px;
  bottom: -67%;
}

.featured-four {
  padding-top: 400px;
}

.featured-two-same {
  padding-top: 144px;
  padding-bottom: 27px;
}
.featured-two-same .section-title {
  margin-bottom: 80px;
}
.featured-two-same .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a {
  color: #2b2350;
}
.featured-two-same .saaspik-icon-box-wrapper.style-two .pixsass-icon-box-content .pixsass-icon-box-title a:hover {
  color: #7052fb;
}
.featured-two-same .scroll-circle {
  bottom: auto;
  top: -50%;
}

.featured-four-ab {
  padding-top: 146px;
}

.featured-nine {
  margin-bottom: -110px;
  z-index: 22;
  padding-top: 50px;
}

.featured-homethree {
  padding-top: 228px;
  padding-bottom: 27px;
}
.featured-homethree .section-title {
  margin-bottom: 80px !important;
}

.down-bg {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

@media (max-width: 1200px) {
  .featured-homethree {
    padding-top: 118px;
  }
  .featured-six {
    padding-top: 50px;
  }
}
@media (max-width: 991px) {
  .featured-two {
    padding: 85px 0 35px;
  }
  .featured-eight {
    padding: 72px 0 50px;
  }
  .featured-two-same {
    padding-top: 74px;
    padding-bottom: 44px;
  }
  .featured {
    padding-top: 75px;
  }
  .featured-three {
    padding: 76px 0 80px;
  }
  .featured-homethree {
    padding-top: 0;
  }
  .saaspik-icon-box-wrapper.style-two {
    margin-bottom: 40px;
    text-align: center;
  }
  .featured-four {
    padding-top: 185px;
    padding-bottom: 40px;
  }
  .featured-homethree {
    padding-bottom: 34px;
  }
  .featured-four-ab {
    padding-top: 76px;
  }
  .featured-two-service {
    padding: 55px 0 43px !important;
  }
  .featured-two-same .scroll-circle {
    left: -20%;
  }
  .featured-three .scroll-circle {
    z-index: 1;
    left: -15%;
    bottom: 20%;
  }
  .featured-three .scroll-circle img {
    max-width: 450px;
  }
  .saaspik-icon-box-wrapper.style-one {
    text-align: center;
  }
  .saaspik-icon-box-wrapper .pixsass-icon-box-content .pixsass-icon-box-title a br {
    display: none;
  }
  .featured-six {
    padding-top: 0;
    padding-bottom: 38px;
  }
  .featured-seven {
    padding: 75px 0 30px;
  }
  .saaspik-icon-box-wrapper.style-seven {
    margin-bottom: 50px;
  }
  .saaspik-icon-box-wrapper.style-eight .pixsass-icon-box-content p br {
    display: none;
  }
}
@media (max-width: 768px) {
  .saaspik-icon-box-wrapper .pixsass-icon-box-content p br {
    display: none;
  }
  .featured-four {
    padding-top: 250px;
  }
  .featured-two-same .scroll-circle {
    left: -30%;
  }
  .featured-three .scroll-circle {
    z-index: 1;
    left: -15%;
    bottom: 20%;
  }
  .featured-three .scroll-circle img {
    max-width: 330px;
  }
  .feature-image-wrapper {
    margin-bottom: 40px;
  }
  .featured-eight .top-bg {
    background: #f97bc5;
    min-height: 350px;
  }
}
@media (max-width: 767px) {
  .featured-four {
    padding-top: 137px;
  }
}
@media (max-width: 576px) {
  .featured-four {
    padding-top: 76px;
  }
}
@media (max-width: 480px) {
  .featured-two-same .scroll-circle {
    left: -70%;
  }
  .saaspik-icon-box-wrapper.style-three {
    text-align: center;
    display: block;
  }
  .saaspik-icon-box-wrapper.style-three .saaspik-icon-box-icon {
    margin: 0 auto 30px;
  }
}
.editor-design {
  padding-top: 113px;
  position: relative;
  padding-bottom: 170px;
}

.editor-design-two {
  padding-top: 210px;
  padding-bottom: 50px;
}

.editure-feature-image {
  position: absolute;
  left: 75px;
}
.editure-feature-image .image-one {
  max-width: 720px;
}
.editure-feature-image .image-two {
  top: 50%;
  position: absolute;
  right: -150px;
  transform: translateY(-50%);
}

.editor-content {
  padding-left: 105px;
  padding-top: 50px;
}
.editor-content .section-title.style-two {
  margin-bottom: 35px;
}
.editor-content .section-title.style-two p {
  color: #ffc107;
  margin-bottom: 0px;
}
.editor-content .section-title.style-two .title {
  margin-bottom: 20px;
}
.editor-content .section-title.style-two.color-two {
  margin-bottom: 35px;
}
.editor-content .section-title.style-two.color-two p {
  color: #7052fb;
  margin: 0;
}
.editor-content .description p {
  margin-bottom: 46px;
}

.shape-bg {
  position: absolute;
  top: -188px;
  left: -10px;
  z-index: -1;
}

.animaated-elements {
  position: relative;
  height: 540px;
  width: 570px;
}
.animaated-elements img,
.animaated-elements .elm-mass {
  position: absolute;
}
.animaated-elements .elm-clock {
  left: 20px;
}
.animaated-elements .elm-man {
  left: 127px;
  bottom: 39px;
}
.animaated-elements .elm-table {
  bottom: 40px;
  left: 45px;
  z-index: 2;
}
.animaated-elements .elm-sm-vase {
  left: 70px;
  bottom: 254px;
}
.animaated-elements .elm-vase {
  bottom: 40px;
  right: -27px;
  z-index: 1;
}
.animaated-elements .elm-mass {
  right: 103px;
  top: 92px;
  width: 135px;
}
.animaated-elements .elm-mass .mass-img {
  animation: jumping 1.6s infinite;
}

.animaated-elements-two {
  position: relative;
  height: 560px;
  width: 570px;
  margin-top: 87px;
  z-index: 22;
}
.animaated-elements-two .elm-two {
  left: 20px;
  bottom: 85px;
}
.animaated-elements-two .elm-three {
  top: -115px;
  right: 40px;
  animation: jump 2s infinite;
}
.animaated-elements-two .elm-four {
  right: 192px;
  bottom: 38px;
}
.animaated-elements-two img {
  position: absolute;
}

.genera-informes-content {
  position: relative;
  z-index: 22;
}

/** Image Content Three **/
#job_image_content {
  padding: 120px 0 80px;
}
@media (max-width: 991px) {
  #job_image_content {
    padding: 80px 0 40px;
  }
}

.animaated-elements-three {
  position: relative;
  margin-left: -190px;
  height: 520px;
}
@media (max-width: 480px) {
  .animaated-elements-three {
    height: 440px;
  }
}
@media (max-width: 576px) {
  .animaated-elements-three {
    margin-left: 0;
  }
}
.animaated-elements-three .map-bg {
  position: absolute;
  top: 100px;
}
.animaated-elements-three .image-one,
.animaated-elements-three .image-two,
.animaated-elements-three .image-three {
  position: absolute;
}
.animaated-elements-three .image-one {
  top: 0;
  left: 140px;
}
@media (max-width: 576px) {
  .animaated-elements-three .image-one {
    left: 0;
  }
}
.animaated-elements-three .image-two {
  right: 50px;
  top: 160px;
}
@media (max-width: 768px) {
  .animaated-elements-three .image-two {
    right: -80px;
  }
}
@media (max-width: 480px) {
  .animaated-elements-three .image-two {
    top: 60px;
  }
}
.animaated-elements-three .image-three {
  top: 291px;
  left: 73px;
}
@media (max-width: 576px) {
  .animaated-elements-three .image-three {
    top: 230px;
    left: -45px;
  }
}
@media (max-width: 480px) {
  .animaated-elements-three .image-three {
    top: 175px;
    left: -45px;
  }
}

.animaated-elements-four {
  position: relative;
  height: 705px;
  margin-right: -100px;
}
@media (max-width: 991px) {
  .animaated-elements-four {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .animaated-elements-four {
    height: 560px;
  }
}
.animaated-elements-four .image-one,
.animaated-elements-four .image-two,
.animaated-elements-four .image-three,
.animaated-elements-four .image-four,
.animaated-elements-four .element-bg-shape {
  position: absolute;
}
.animaated-elements-four .element-bg-shape {
  right: -25px;
  top: 155px;
}
.animaated-elements-four .image-one {
  left: 130px;
  top: 60px;
  z-index: 2;
}
@media (max-width: 991px) {
  .animaated-elements-four .image-one {
    left: 60px;
  }
}
@media (max-width: 480px) {
  .animaated-elements-four .image-one {
    left: 15px;
    max-width: 300px;
  }
}
.animaated-elements-four .image-two {
  top: 0;
  left: 300px;
}
@media (max-width: 991px) {
  .animaated-elements-four .image-two {
    left: 145px;
  }
}
@media (max-width: 480px) {
  .animaated-elements-four .image-two {
    left: 90px;
  }
}
.animaated-elements-four .image-three {
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
}
.animaated-elements-four .image-four {
  bottom: 82px;
  right: 95px;
  z-index: -1;
}
.animaated-elements-four .image-four img {
  animation: wave 6s infinite linear;
}
@media (max-width: 991px) {
  .animaated-elements-four .image-four {
    bottom: 150px;
    right: 30px;
  }
}

@media (max-width: 991px) {
  .image-content-three {
    margin-top: 50px;
  }
}
.image-content-three .section-title {
  margin-bottom: 41px;
}
.image-content-three .section-title .title {
  font-size: 38px;
  line-height: 1.1;
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .image-content-three .section-title .title {
    font-size: 34px;
  }
}
.image-content-three .section-title p {
  margin-bottom: 18px;
}
.image-content-three.app-content-wrap .section-title {
  margin-bottom: 37px;
}

#app_image_content {
  padding: 150px 0;
}
@media (max-width: 991px) {
  #app_image_content {
    padding: 80px 0;
  }
}

.app-image-content-wrapper-one {
  margin-bottom: 200px;
}
@media (max-width: 991px) {
  .app-image-content-wrapper-one {
    margin-bottom: 100px;
  }
}

.app-download-image {
  height: 500px;
  width: 500px;
  background: #f6ecd8;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
}
@media (max-width: 576px) {
  .app-download-image {
    width: 380px;
    height: 380px;
    margin: 0 auto;
  }
  .app-download-image img {
    width: 300px;
  }
}
@media (max-width: 420px) {
  .app-download-image {
    width: 300px;
    height: 300px;
    margin: 0 auto;
  }
  .app-download-image img {
    width: 250px;
  }
}
.app-download-image img {
  margin-top: -12px;
}

.job-image-content-wrapper-one {
  margin-bottom: 160px;
}
@media (max-width: 991px) {
  .job-image-content-wrapper-one {
    margin-bottom: 100px;
  }
}
.job-image-content-wrapper-one .animaated-elements-three {
  padding-top: 40px;
}

@media (max-width: 1600px) {
  .editure-feature-image .image-one {
    max-width: 600px;
  }
  .editure-feature-image .image-two {
    margin-left: -124px;
    max-width: 220px;
  }
  .editor-design {
    padding-bottom: 100px;
  }
  .editor-design .shape-bg {
    max-width: 1200px;
  }
}
@media (max-width: 1440px) {
  .editure-feature-image .image-one {
    max-width: 550px;
  }
  .editure-feature-image .image-two {
    right: -130px;
    max-width: 200px;
  }
  .editor-content {
    padding-top: 0;
  }
}
@media (max-width: 1280px) {
  .editor-content {
    padding-left: 0;
  }
  .editure-feature-image {
    left: 20px;
  }
  .editure-feature-image .image-one {
    max-width: 480px;
  }
  .editure-feature-image .image-two {
    margin-left: -100px;
    max-width: 180px;
  }
  .editor-content {
    padding-top: 0;
  }
  .editor-design {
    padding-top: 70px;
    padding-bottom: 100px;
  }
  .editor-design .shape-bg {
    max-width: 1100px;
  }
  .editure-feature-image .image-two {
    right: -120px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .editor-design-two {
    padding-top: 160px;
  }
  .editor-design {
    padding-top: 100px;
    padding-bottom: 0;
  }
  .editor-content {
    padding-left: 50px;
  }
  .animaated-elements-two {
    left: -90px;
  }
  .genera-informes-content {
    padding-right: 50px;
  }
  .animaated-elements {
    width: 470px;
    height: 440px;
  }
  .animaated-elements .elm-clock {
    width: 120px;
  }
  .animaated-elements .elm-table {
    width: 380px;
    bottom: 25px;
  }
  .animaated-elements .elm-man {
    width: 225px;
  }
  .animaated-elements .elm-sm-vase {
    width: 35px;
    bottom: 205px;
  }
  .animaated-elements .elm-vase {
    width: 120px;
    bottom: 27px;
  }
  .animaated-elements .elm-mass {
    right: 60px;
  }
  .animaated-elements .elm-mass img {
    width: 110px;
  }
  .animaated-elements-two {
    width: 470px;
    height: 460px;
    left: 0;
  }
  .animaated-elements-two .elm-two {
    bottom: 65px;
    width: 120px;
    left: 25px;
  }
  .animaated-elements-two .elm-three {
    width: 180px;
    top: -70px;
  }
  .animaated-elements-two .elm-four {
    width: 160px;
    right: 160px;
  }
}
@media (max-width: 1024px) {
  .editor-content {
    padding-left: 100px;
  }
}
@media (max-width: 991px) {
  .editor-design {
    padding-top: 62px;
    padding-bottom: 70px;
  }
  .editor-design-two {
    padding-top: 90px;
    padding-bottom: 30px;
  }
  .editure-feature-image {
    position: relative;
    left: 0;
  }
  .editure-feature-image .image-two {
    position: absolute;
    top: 50%;
    right: -82px;
    transform: translateY(-50%);
  }
  .genera-informes-content {
    padding-bottom: 30px;
  }
  .editor-content {
    padding-left: 0;
    padding-top: 17px;
  }
  .editor-content .section-title.style-two.color-two {
    margin-bottom: 20px;
  }
  .editor-content .section-title.style-two .title {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .animaated-elements {
    left: -50px;
  }
  .animaated-elements {
    width: 470px;
    height: 440px;
  }
  .animaated-elements .elm-clock {
    width: 120px;
  }
  .animaated-elements .elm-table {
    width: 380px;
    bottom: 25px;
  }
  .animaated-elements .elm-man {
    width: 225px;
  }
  .animaated-elements .elm-sm-vase {
    width: 35px;
    bottom: 205px;
  }
  .animaated-elements .elm-vase {
    width: 120px;
    bottom: 27px;
  }
  .animaated-elements .elm-mass {
    right: 60px;
  }
  .animaated-elements .elm-mass img {
    width: 110px;
  }
  .animaated-elements-two {
    width: 470px;
    height: 460px;
    margin-top: 35px;
  }
  .animaated-elements-two .elm-two {
    bottom: 65px;
    width: 120px;
    left: 25px;
  }
  .animaated-elements-two .elm-three {
    width: 180px;
    top: -70px;
  }
  .animaated-elements-two .elm-four {
    width: 160px;
    right: 160px;
  }
}
@media (max-width: 576px) {
  .editure-feature-image {
    position: relative;
    left: 0;
    margin-bottom: 30px;
  }
  .editure-feature-image .image-one {
    max-width: 400px;
  }
  .editure-feature-image .image-two {
    right: -80px;
  }
  .editor-content {
    padding-left: 0;
  }
  .animaated-elements {
    width: 320px;
    height: 340px;
    left: 0;
  }
  .animaated-elements .elm-clock {
    width: 80px;
  }
  .animaated-elements .elm-table {
    width: 260px;
    bottom: 35px;
  }
  .animaated-elements .elm-man {
    width: 160px;
  }
  .animaated-elements .elm-sm-vase {
    width: 25px;
    bottom: 158px;
  }
  .animaated-elements .elm-vase {
    width: 70px;
    bottom: 35px;
  }
  .animaated-elements .elm-mass {
    right: -40px;
    top: 80px;
  }
  .animaated-elements .elm-mass img {
    width: 70px;
  }
  .animaated-elements-two {
    width: 330px;
    height: 340px;
  }
  .animaated-elements-two .elm-two {
    bottom: 65px;
    width: 70px;
    left: 25px;
  }
  .animaated-elements-two .elm-three {
    width: 120px;
    top: -25px;
  }
  .animaated-elements-two .elm-four {
    width: 105px;
    right: 120px;
  }
  .genera-informes-two .scroll-circle {
    left: -70% !important;
  }
}
@media (max-width: 480px) {
  .editure-feature-image .image-one {
    max-width: 300px;
  }
  .editure-feature-image .image-two {
    right: 0;
  }
  .editure-feature-image .image-two-inner img {
    max-width: 150px;
  }
  .genera-informes-two .scroll-circle {
    left: -100% !important;
  }
}
.genera-informes {
  padding-top: 197px;
  padding-bottom: 222px;
  overflow: hidden;
}
.genera-informes .section-title {
  margin-bottom: 30px;
}
.genera-informes .list-items {
  margin-bottom: 50px;
}
.genera-informes .shape-bg {
  left: auto;
  top: 0;
  right: 0;
}
.genera-informes-two {
  padding-top: 105px;
}
.genera-informes-two .section-title {
  margin-top: 115px;
  margin-bottom: 36px;
}
.genera-informes-two .list-items {
  margin-bottom: 50px;
}

.informes-feature-image {
  position: absolute;
  right: 75px;
  top: 143px;
}
.informes-feature-image .image-one {
  max-width: 720px;
}
.informes-feature-image .image-two {
  position: absolute;
  right: 150px;
  bottom: -140px;
  max-width: 720px;
}

.genera-informes-two .scroll-circle {
  top: 60px;
}

.genera-informes-three {
  padding-top: 242px;
  position: relative;
}
.genera-informes-three .shape-bg {
  left: auto;
  right: 0;
  top: 40px;
}
.genera-informes-three .list-items {
  margin-bottom: 50px;
}
.genera-informes-three .section-title {
  margin-bottom: 35px;
}

@media (max-width: 1680px) {
  .informes-feature-image {
    position: absolute;
    right: 50px;
    top: 125px;
  }
  .informes-feature-image .image-one {
    max-width: 620px;
  }
  .informes-feature-image .image-two {
    position: absolute;
    right: 130px;
    bottom: -140px;
  }
  .informes-feature-image .image-two img {
    max-width: 620px !important;
  }
}
@media (max-width: 1600px) {
  .genera-informes {
    padding-bottom: 120px;
  }
  .genera-informes .shape-bg {
    max-width: 500px;
  }
  .informes-feature-image {
    top: 80px;
  }
}
@media (max-width: 1440px) {
  .informes-feature-image {
    position: absolute;
    right: 25px;
    top: 125px;
  }
  .informes-feature-image .image-one {
    max-width: 550px;
  }
  .informes-feature-image .image-two {
    position: absolute;
    right: 100px;
    bottom: -140px;
  }
  .informes-feature-image .image-two img {
    max-width: 550px !important;
  }
}
@media (max-width: 1280px) {
  .genera-informes {
    padding-bottom: 120px;
  }
  .genera-informes .shape-bg {
    max-width: 400px;
  }
  .informes-feature-image {
    top: 70px;
  }
}
@media (max-width: 1200px) {
  .informes-feature-image {
    position: absolute;
    right: 25px;
    top: 100px;
  }
  .informes-feature-image .image-one {
    max-width: 450px;
  }
  .informes-feature-image .image-two {
    position: absolute;
    right: 70px;
    bottom: -140px;
  }
  .informes-feature-image .image-two img {
    max-width: 450px !important;
  }
  .genera-informes {
    padding-top: 140px;
  }
  .genera-informes .shape-bg {
    right: -180px;
  }
  .genera-informes-three {
    padding-top: 180px;
  }
  .genera-informes-three .shape-bg {
    width: 500px;
  }
}
@media (max-width: 1024px) {
  .informes-feature-image {
    position: absolute;
    right: 25px;
    top: 75px;
  }
  .informes-feature-image .image-one {
    max-width: 450px;
  }
  .informes-feature-image .image-two {
    position: absolute;
    right: 70px;
    bottom: -140px;
    max-width: 450px;
  }
  .genera-informes .shape-bg {
    right: -180px;
  }
}
@media (max-width: 991px) {
  .genera-informes {
    padding-top: 0;
    overflow: hidden;
    padding-bottom: 80px;
  }
  .genera-informes .section-title {
    margin-bottom: 20px;
    padding-top: 0;
  }
  .genera-informes .list-items {
    margin-bottom: 40px;
  }
  .genera-informes .informes-feature-image {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 0;
    margin-bottom: 120px;
    padding-top: 0;
  }
  .genera-informes .informes-feature-image .image-one {
    max-width: 450px;
  }
  .genera-informes .informes-feature-image .image-two {
    position: absolute;
    right: -70px;
    bottom: -110px;
    max-width: 450px;
  }
  .informes-feature-image .image-two {
    bottom: -100px;
  }
  .genera-informes-two {
    padding-top: 46px;
  }
  .genera-informes-two .section-title {
    margin-top: 71px;
    margin-bottom: 25px;
  }
  .genera-informes-two .list-items {
    margin-bottom: 40px;
  }
  .genera-informes-three {
    padding-top: 0;
  }
  .genera-informes-three .section-title {
    margin-top: 96px;
  }
  .genera-informes-three .section-title.style-two {
    margin-top: 100px;
  }
  .genera-informes-three .informes-feature-image {
    position: relative;
    top: 0;
    right: 0;
  }
  .genera-informes-three .informes-feature-image .image-two {
    right: auto;
    left: 70px;
  }
  .genera-informes .shape-bg {
    right: -180px;
  }
  .genera-informes-two .scroll-circle {
    left: -40%;
  }
}
@media (max-width: 768px) {
  .informes-feature-image .image-two {
    right: -70px;
  }
}
@media (max-width: 576px) {
  .informes-feature-image .image-two {
    left: 0;
    right: auto;
  }
  .informes-feature-image .image-one img,
  .informes-feature-image .image-two img {
    max-width: 100% !important;
  }
}
.revolutionize {
  padding: 116px 0 30px;
}
.revolutionize-two {
  padding: 116px 0 30px;
}
.revolutionize-two .bg-angle {
  background-image: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);
}

.bg-angle {
  background-image: linear-gradient(100deg, #ffc107 0%, #ffb507 100%);
  max-height: 700px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
}
.bg-angle:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-left: 1900px solid #fff;
  border-bottom: 0px solid transparent;
}

/* Tabs */
#pix-tabs-nav {
  text-align: center;
  list-style: none;
  margin-bottom: 38px;
  padding: 0;
}
#pix-tabs-nav li {
  display: inline-block;
}
#pix-tabs-nav li:not(:last-child) {
  margin-right: 20px;
}
#pix-tabs-nav li a {
  color: #f1e8e8;
  font-weight: 500;
  cursor: pointer;
  padding: 11px 27px;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 20px;
}
#pix-tabs-nav li a:hover {
  background: #fff;
  color: #ffc107;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
#pix-tabs-nav li.active a {
  background: #fff;
  color: #ffc107;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
#pix-tabs-nav.pix-tab-two li a:hover {
  color: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}
#pix-tabs-nav.pix-tab-two li.active a {
  color: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}

#pix-tabs-content .content {
  text-align: center;
  position: relative;
}
#pix-tabs-content .content .shape-shadow {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.2);
  height: 100px;
  max-width: 73%;
  position: absolute;
  bottom: -40px;
  width: 100%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
#pix-tabs-content .content.color-two img {
  box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
}
#pix-tabs-content .content.color-two .shape-shadow {
  box-shadow: 0px 60px 100px 0px rgba(43, 35, 79, 0.2);
}

@media (max-width: 991px) {
  .revolutionize,
  .revolutionize-two {
    padding: 76px 0 30px;
  }
  #pix-tabs-content .content.color-two .shape-shadow {
    box-shadow: 0px 40px 50px 0px rgba(43, 35, 79, 0.2);
  }
}
@media (max-width: 768px) {
  #pix-tabs-content .content .shape-shadow {
    bottom: -30px;
    box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
  }
  #pix-tabs-nav li:not(:last-child) {
    margin-right: 5px;
  }
}
@media (max-width: 576px) {
  #pix-tabs-nav {
    margin: 0;
  }
  #pix-tabs-nav li {
    margin-right: 3px;
  }
  #pix-tabs-nav li a {
    font-size: 14px;
    padding: 6px 22px;
  }
}
@media (max-width: 420px) {
  #pix-tabs-nav {
    margin-bottom: 20px;
  }
  #pix-tabs-nav li a {
    margin-bottom: 10px;
  }
  #pix-tabs-nav li:not(:last-child) {
    margin-right: 0;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: none;
}

.testimonials {
  background: #fffbe9;
  padding: 116px 0 0;
  overflow: hidden;
}
.testimonials .scroll-circle {
  bottom: 35%;
  right: -133px;
}

.testimonial-wrapper {
  padding: 0 100px 160px;
  position: relative;
  z-index: 22;
}
.testimonial-wrapper .shape-shadow {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  box-shadow: 0px 60px 100px 0px rgba(102, 74, 74, 0.2);
  height: 100px;
  max-width: 54%;
  position: absolute;
  bottom: 120px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-wrapper .slider-nav .swiper-button-next,
.testimonial-wrapper .slider-nav .swiper-button-prev {
  color: #bdb4b4;
  font-size: 36px;
  transition: all 0.3s ease-in-out;
  outline: 0;
}
.testimonial-wrapper .slider-nav .swiper-button-next:hover,
.testimonial-wrapper .slider-nav .swiper-button-prev:hover {
  color: #ffc107;
}
.testimonial-wrapper .slider-nav .swiper-button-next {
  right: 20px;
}
.testimonial-wrapper .slider-nav .swiper-button-prev {
  left: 20px;
}

#testimonial {
  border-radius: 10px;
  max-width: 770px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0px 60px 100px 0px rgba(79, 35, 35, 0.1);
  background: #fff;
  width: 100%;
}
#testimonial .testimonial {
  text-align: center;
  margin: 0 auto;
  padding: 60px;
  position: relative;
}
#testimonial .testimonial .testimonial-content {
  max-width: 630px;
  margin: 0 auto 30px;
}
#testimonial .testimonial .testimonial-content p {
  color: #656177;
  font-size: 20px;
  margin: 0;
  line-height: 38px;
}
#testimonial .testimonial .single-bio-thumb {
  max-width: 80px;
  margin: 0 auto 41px;
  box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
  border-radius: 50%;
}
#testimonial .testimonial .single-bio-thumb img {
  border-radius: 50%;
}
#testimonial .testimonial .bio-info .name {
  font-size: 20px;
  line-height: 32px;
  margin: 0;
}

.testimonials-two {
  position: relative;
  overflow: hidden;
}
.testimonials-two .section-title {
  margin-bottom: 50px;
}
.testimonials-two .animate-shape {
  position: absolute;
  right: -9%;
  top: -37%;
}

#testimonial-wrapper {
  position: relative;
  z-index: 2;
}
#testimonial-wrapper .slider-nav .swiper-button-prev,
#testimonial-wrapper .slider-nav .swiper-button-next {
  outline: 0;
}
#testimonial-wrapper .slider-nav .swiper-button-prev i,
#testimonial-wrapper .slider-nav .swiper-button-next i {
  font-size: 32px;
}
#testimonial-wrapper .slider-nav .swiper-button-prev:hover,
#testimonial-wrapper .slider-nav .swiper-button-next:hover {
  color: #7052fb;
}
#testimonial-wrapper .slider-nav .swiper-button-next {
  right: -100px;
}
#testimonial-wrapper .slider-nav .swiper-button-prev {
  left: -100px;
}

.testimonials-two,
.testimonials-two-about {
  padding: 116px 0 60px;
}
.testimonials-two .swiper-container,
.testimonials-two-about .swiper-container {
  padding: 0 20px 100px;
}

.testimonials-two {
  background: #f8f7fc;
}

.testimonial-two .testi-content-inner {
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0px 30px 20px 0px rgba(43, 35, 79, 0.05);
  border-radius: 10px;
  padding: 50px 60px;
}
.testimonial-two .testimonial-bio {
  display: flex;
  align-items: center;
  margin-bottom: 43px;
}
.testimonial-two .testimonial-bio .avatar {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 30px;
}
.testimonial-two .testimonial-bio .avatar img {
  border-radius: 50%;
}
.testimonial-two .testimonial-bio .bio-info .name {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
}
.testimonial-two .testimonial-content {
  margin-bottom: 35px;
}
.testimonial-two .testimonial-content p {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}
.testimonial-two .shape-shadow {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  box-shadow: 0px 20px 50px 0px rgba(102, 74, 74, 0.2);
  height: 100px;
  max-width: 81%;
  position: absolute;
  bottom: -40px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.testimonial-two .quote {
  position: absolute;
  right: 60px;
}

.rating {
  margin: 0;
  padding: 0;
  list-style: none;
}
.rating li {
  display: inline-block;
  font-size: 14px;
  color: #fdae04;
}

#testimonial-wrapper-three {
  max-width: 1060px;
  margin: 0 auto;
  position: relative;
}
#testimonial-wrapper-three .quote {
  position: absolute;
  bottom: 80px;
  right: 80px;
  z-index: 22;
}
#testimonial-wrapper-three .swiper-container {
  padding: 0 45px 80px;
}
#testimonial-wrapper-three .slider-nav {
  width: 90px;
  top: auto;
  bottom: 100px;
  left: 50%;
  height: 40px;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev {
  height: 25px;
  transition: all 0.3s ease-in-out;
  outline: 0;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow {
  margin-top: 12px;
  width: 25px;
  height: 2px;
  background: #bdb4b4;
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  top: 7px;
  left: -2px;
  background-color: #bdb4b4;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev .arrow:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  top: 17px;
  left: -2px;
  background-color: #bdb4b4;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover {
  width: 40px;
  left: -15px;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow {
  width: 40px;
  background: #ffc107;
}
#testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow:before, #testimonial-wrapper-three .slider-nav .swiper-button-prev:hover .arrow:after {
  background: #ffc107;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next {
  height: 25px;
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow {
  margin-top: 12px;
  width: 25px;
  height: 2px;
  background: #bdb4b4;
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  top: 7px;
  right: -2px;
  background-color: #bdb4b4;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next .arrow:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  top: 17px;
  right: -2px;
  background-color: #bdb4b4;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next:hover {
  width: 40px;
  right: -15px;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow {
  width: 40px;
  background: #ffc107;
}
#testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow:before, #testimonial-wrapper-three .slider-nav .swiper-button-next:hover .arrow:after {
  background: #ffc107;
}

.testimonials-two-about {
  background: #fffbe9;
  padding: 116px 0 58px;
  overflow: hidden;
}
.testimonials-two-about .testimonial-two .testi-content-inner {
  box-shadow: 0px 30px 20px 0px rgba(79, 35, 35, 0.1);
}
.testimonials-two-about .testimonial-two .shape-shadow {
  box-shadow: 0px 20px 50px 0px rgba(79, 35, 35, 0.1);
}
.testimonials-two-about .scroll-circle {
  bottom: 33%;
  z-index: 1;
  right: -7%;
}
.testimonials-two-about #testimonial-wrapper .slider-nav .swiper-button-prev:hover,
.testimonials-two-about #testimonial-wrapper .slider-nav .swiper-button-next:hover {
  color: #ffc107;
}

.testimonial-three {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.testimonial-three .avatar,
.testimonial-three .testimonial-content {
  flex: 1;
}
.testimonial-three .testimonial-content {
  padding: 0 60px;
  margin-top: -47px;
}
.testimonial-three .testimonial-content .bio-info {
  margin-bottom: 30px;
}
.testimonial-three .testimonial-content .bio-info .name {
  font-size: 20px;
  font-weight: 600;
}
.testimonial-three .testimonial-content p {
  font-size: 18px;
  line-height: 32px;
  color: #656177;
}

.testimonials-three {
  padding: 116px 0 40px;
}

.testimonial-wrapper-two .slider-nav {
  top: 50%;
}

.testimonials-four {
  background: #fbfafd;
  padding: 115px 0;
}

.testimonial-wrapper-two {
  position: relative;
}
.testimonial-wrapper-two .slider-nav #slide-prev,
.testimonial-wrapper-two .slider-nav #slide-next {
  outline: 0;
  height: 50px;
  width: 50px;
  line-height: 54px;
  border: 1px solid #e0deed;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.testimonial-wrapper-two .slider-nav #slide-prev:hover,
.testimonial-wrapper-two .slider-nav #slide-next:hover {
  background: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
  color: #fff;
}

.testimonials-tax {
  padding: 112px 0 120px;
  position: relative;
}
.testimonials-tax .testi-top-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.testimonial-six-wrapper {
  margin: 0 auto;
  max-width: 770px;
  position: relative;
}
.testimonial-six-wrapper .quote {
  position: absolute;
  top: 40px;
  left: 30px;
}
.testimonial-six-wrapper .testimonial-six {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 50px 80px 0px rgba(48, 11, 33, 0.1);
  padding: 50px 100px 100px;
}
.testimonial-six-wrapper .testimonial-six .testimonial .single-bio-thumb {
  border-radius: 50%;
  background-color: rgb(223, 228, 236);
  box-shadow: 0px 30px 40px 0px rgba(48, 11, 33, 0.12);
  width: 80px;
  height: 80px;
  margin: 0 auto 30px;
  overflow: hidden;
}
.testimonial-six-wrapper .testimonial-six .testimonial .testimonial-content p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 35px;
}
.testimonial-six-wrapper .testimonial-six .testimonial .bio-info .name {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}
.testimonial-six-wrapper .testimonial-six .testimonial .bio-info .job {
  font-size: 14px;
}
.testimonial-six-wrapper .dot-shape {
  position: absolute;
  position: absolute;
  right: -60px;
  bottom: -35px;
  z-index: -1;
}

.style-two.swiper-pagination {
  margin-top: 35px;
}
.style-two.swiper-pagination .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
  transition: all 0.3s ease-in-out;
  margin-right: 5px;
  position: relative;
  opacity: 1;
  background: transparent;
  outline: 0;
}
.style-two.swiper-pagination .swiper-pagination-bullet:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  background: rgb(229, 214, 214);
  z-index: 2;
  top: 0;
  left: 0;
  border-radius: 50%;
  left: -2px;
  top: -2px;
  transition: all 0.3s ease-in-out;
  transform: scale(0.7);
}
.style-two.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.style-two.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  opacity: 1;
  transform: scale(1);
  background: #f97bc5;
  box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
}

.testimonials-six {
  padding: 120px 0 154px;
  background-size: cover;
  background-position: center center;
}

#testimonial-wrapper-six {
  margin: 0 -45px;
}
#testimonial-wrapper-six .swiper-container {
  padding: 40px 45px 35px;
}

.swiper-slide-visible .testimonial-six .testimonial-content {
  box-shadow: 0px 40px 50px 0px rgba(10, 1, 47, 0.12);
}

.testimonial-six .testimonial-content {
  background-color: #fff;
  padding: 42px 40px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.testimonial-six .testimonial-content p {
  font-size: 16px;
  line-height: 30px;
  color: #322d49;
  margin-bottom: 13px;
}
.testimonial-six .testimonial-content:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 8px solid transparent;
  border-top: 15px solid #fff;
  bottom: -15px;
  position: absolute;
  left: 100px;
}
.testimonial-six .testimonial-bio {
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-left: 100px;
}
.testimonial-six .testimonial-bio .name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.testimonial-six .testimonial-bio .avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four > * {
  transform: translateY(0);
  opacity: 1;
}
#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .single-bio-thumb {
  transition-delay: 0.3s;
}
#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .testimonial-content {
  transition-delay: 0.5s;
}
#testimonials-four .swiper-slide.swiper-slide-active .testimonial-four .bio-info {
  transition-delay: 0.7s;
}

.testimonial-four {
  text-align: center;
  padding-top: 30px;
}
.testimonial-four > * {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.5s;
}
.testimonial-four .single-bio-thumb {
  overflow: hidden;
  height: 80px;
  width: 80px;
  margin: 0 auto 60px;
  border-radius: 50%;
  box-shadow: 0px 20px 30px 0px rgba(43, 35, 79, 0.1);
}
.testimonial-four .testimonial-content {
  position: relative;
  max-width: 740px;
  margin: 0 auto 68px;
}
.testimonial-four .testimonial-content .quote {
  position: absolute;
  top: -65px;
  left: 0;
  z-index: 1;
}
.testimonial-four .testimonial-content p {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
  margin: 0;
  z-index: 22;
  position: relative;
}
.testimonial-four .bio-info .name {
  font-size: 20px;
  font-weight: 500;
  color: #322d49;
  margin: 0;
}
.testimonial-four .bio-info .job {
  font-size: 14px;
  color: #7c7a8f;
}

@media (max-width: 1440px) {
  .testimonials-two .animate-shape,
  .testimonials-two .scroll-circle,
  .testimonials-two-about .animate-shape,
  .testimonials-two-about .scroll-circle {
    max-width: 600px;
  }
}
@media (max-width: 1200px) {
  #testimonial-wrapper-three .slider-nav {
    bottom: 70px;
  }
}
@media (max-width: 1024px) {
  .testimonial-three .testimonial-content {
    padding: 0 20px;
    margin-top: 0;
  }
  .testimonial-three .testimonial-content p {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial-three .testimonial-content .bio-info {
    margin-bottom: 10px;
  }
  #testimonial-wrapper-three .slider-nav {
    bottom: 80px;
  }
  #testimonial-wrapper-three .quote {
    width: 80px;
  }
  .testimonials .scroll-circle {
    max-width: 500px;
  }
  .testimonials-two-about .scroll-circle {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .testimonials {
    padding: 76px 0 10px;
  }
  .testimonials-two .swiper-container,
  .testimonials-two-about .swiper-container {
    padding: 0 20px 80px;
  }
  .testimonials-two,
  .testimonials-two-about {
    padding: 76px 0 30px;
  }
  #testimonial-wrapper .slider-nav .swiper-button-prev {
    left: -30px;
  }
  #testimonial-wrapper .slider-nav .swiper-button-next {
    right: -20px;
  }
  .testimonials-three {
    padding: 76px 0 0;
  }
  #testimonial-wrapper-three .slider-nav {
    display: none;
  }
  .testimonials-two .animate-shape {
    right: -80%;
  }
  .testimonial-wrapper {
    padding: 0 50px 110px;
  }
  .testimonial-wrapper .slider-nav .swiper-button-prev {
    left: 0;
  }
  .testimonial-wrapper .slider-nav .swiper-button-next {
    right: 10px;
  }
  .testimonial-wrapper .shape-shadow {
    bottom: 80px;
  }
  #testimonial .testimonial .testimonial-content p {
    font-size: 18px;
    line-height: 34px;
  }
  .testimonial-two .testi-content-inner {
    padding: 40px 30px;
  }
  .testimonial-two .shape-shadow {
    bottom: -30px;
  }
  .testimonial-four .single-bio-thumb {
    margin: 0 auto 30px;
  }
  .testimonial-four .testimonial-content {
    max-width: 500px;
    margin: 0 auto 38px;
  }
  .testimonial-four .testimonial-content p {
    font-size: 18px;
  }
  .testimonial-four .testimonial-content p br {
    display: none;
  }
  .testimonials-four {
    padding: 50px 0 70px;
  }
  .testimonials-tax {
    padding: 72px 0 80px;
  }
}
@media (max-width: 768px) {
  .testimonials {
    padding: 76px 0 30px;
  }
  .testimonials-three {
    padding: 76px 0 30px;
  }
  #testimonial-wrapper-three .swiper-container {
    padding: 0 25px 50px;
  }
  #testimonial .testimonial {
    padding: 40px;
  }
  #testimonial .testimonial .testimonial-content p {
    font-size: 16px;
    line-height: 30px;
  }
  .testimonial-wrapper {
    padding: 0 50px 70px;
  }
  .testimonial-wrapper .shape-shadow {
    bottom: 50px;
  }
  #testimonial-wrapper .slider-nav .swiper-button-prev {
    left: -30px;
  }
  #testimonial-wrapper .slider-nav .swiper-button-next {
    right: -20px;
  }
  .testimonials-two .animate-shape {
    right: -100%;
  }
  .testimonial-three {
    display: block;
    padding: 30px;
  }
  .testimonial-three .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto 30px;
    overflow: hidden;
  }
  .testimonial-three .avatar img {
    border-radius: 50%;
  }
  #testimonial-wrapper-three .slider-nav {
    bottom: 50px;
    transform: translateX(-50%);
    display: none;
  }
  #testimonial-wrapper-three .quote {
    bottom: 50px;
  }
  .testimonial-wrapper-two .slider-nav #slide-prev {
    left: -50px;
  }
  .testimonial-wrapper-two .slider-nav #slide-next {
    right: -50px;
  }
  .testimonial-six-wrapper .testimonial-six {
    padding: 50px 50px 100px;
  }
}
@media (max-width: 620px) {
  .testimonial-four .testimonial-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .testimonial-wrapper-two .slider-nav {
    display: none;
  }
}
@media (max-width: 576px) {
  #testimonial-wrapper .slider-nav .swiper-button-prev,
  #testimonial-wrapper .slider-nav .swiper-button-next,
  .testimonial-wrapper .slider-nav .swiper-button-prev,
  .testimonial-wrapper .slider-nav .swiper-button-next {
    display: none;
  }
  .testimonial-wrapper {
    padding: 0 0 70px;
  }
  #testimonial .testimonial {
    padding: 40px 25px;
  }
  #testimonial-wrapper-three .slider-nav {
    display: none;
  }
  #testimonial-wrapper-three .swiper-container {
    padding: 0 20px 50px !important;
  }
  #testimonial-wrapper-three .quote {
    right: 30px;
  }
  .testimonial-three {
    padding: 30px 10px;
  }
}
.pricing {
  padding: 116px 0 120px;
}
.pricing-two {
  padding-top: 146px;
  padding-bottom: 90px;
}
.pricing-two-single {
  padding-top: 116px;
  padding-bottom: 90px;
}
.pricing .scroll-circle {
  bottom: 20%;
}

.pricing-single-one {
  padding: 116px 0 90px;
}

.pricing-table {
  border-right: 1px solid #efe7e7;
  padding: 20px 40px;
  margin-bottom: 30px;
}
.pricing-table.br-left {
  border-left: 1px solid #efe7e7;
}
.pricing-table .pricing-header {
  text-align: center;
  padding-bottom: 55px;
  border-bottom: 1px dashed #efe7e7;
  margin-bottom: 50px;
}
.pricing-table .pricing-header .price {
  color: #ffc107;
  font-size: 36px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 25px;
}
.pricing-table .pricing-header .price-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.pricing-table .pricing-header p {
  margin: 0;
}
.pricing-table .pricing-header .monthly_price {
  display: none;
}
.pricing-table .pricing-header.change-subs-duration .annual_price {
  display: none;
}
.pricing-table .pricing-header.change-subs-duration .monthly_price {
  display: block;
}
.pricing-table .pricing-action .monthly_price {
  display: none;
}
.pricing-table .pricing-action.change-subs-duration .annual_price {
  display: none;
}
.pricing-table .pricing-action.change-subs-duration .monthly_price {
  display: block;
}
.pricing-table .price-feture {
  padding: 0;
  list-style: none;
  margin-bottom: 55px;
}
.pricing-table .price-feture li {
  padding-left: 33px;
  position: relative;
  margin-bottom: 12px;
}
.pricing-table .price-feture li:before {
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  top: 0;
}
.pricing-table .price-feture li.have:before {
  content: "\f00c";
  color: #1acb44;
}
.pricing-table .price-feture li.not:before {
  content: "M";
  color: #fa7878;
}
.pricing-table.color-two .pricing-header .price {
  color: #8070fa;
}
.pricing-table.color-three .pricing-header .price {
  color: #22cd1a;
}
.pricing-table .pix-btn {
  text-align: center;
  font-weight: 600;
  padding: 8px 0;
  width: 200px;
  text-align: center;
  z-index: 999999999;
}
.pricing-table.style-two {
  background: #fcf7f7;
  padding: 50px 50px 60px;
  transition: all 0.3s ease-in-out;
  position: relative;
  border: 0;
}
.pricing-table.style-two.price-two {
  background: #f8f7fc;
}
.pricing-table.style-two.price-two.featured .trend:before {
  border-right: 90px solid #7052fb;
}
.pricing-table.style-two .pricing-header {
  text-align: left;
  border-color: #b4b2bf;
  padding-bottom: 60px;
}
.pricing-table.style-two .pricing-header .price-title {
  letter-spacing: 0;
  text-transform: capitalize;
}
.pricing-table.style-two:hover {
  background: #fff;
  box-shadow: 0px 40px 80px 0px rgba(43, 35, 79, 0.14);
}
.pricing-table.featured .trend {
  position: absolute;
  top: 0;
  right: 0;
}
.pricing-table.featured .trend p {
  color: #fff;
  transform: rotate(45deg);
  margin-left: 22px;
  margin-bottom: 0;
  z-index: 22;
  font-size: 14px;
  padding-top: 17px;
  padding-bottom: 15px;
}
.pricing-table.featured .trend:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 90px solid transparent;
  border-right: 90px solid #ffc107;
  position: absolute;
  top: 0;
  right: 0;
}

.tabed-content .tabs-item {
  display: none;
}

.pricing-tab {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  z-index: 2;
}
.pricing-tab .tab-btn {
  cursor: pointer;
}
.pricing-tab .annual_tab_title {
  color: #ffc107;
}
.pricing-tab .pricing-tab-switcher {
  height: 40px;
  width: 90px;
  display: inline-block;
  border-radius: 50px;
  position: relative;
  transition: all 0.3s ease;
  margin: 0 18px;
  transform: translateY(-3px);
  border: 1px solid #dcd2d2;
  border-radius: 30px;
  cursor: pointer;
}
.pricing-tab .pricing-tab-switcher:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 4px;
  background: #ffc107;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: all 0.3s ease;
  box-shadow: 0px 14px 24px 0px rgba(176, 70, 70, 0.4);
}
.pricing-tab .pricing-tab-switcher.active:before {
  left: calc(100% - 35px);
}
.pricing-tab.seleceted .annual_tab_title {
  color: #797687;
}
.pricing-tab.seleceted .monthly_tab_title {
  color: #ffc107;
}
.pricing-tab span {
  vertical-align: middle;
}
.pricing-tab.color-two .pricing-tab-switcher:before {
  background: #7052fb;
  box-shadow: 0px 14px 24px 0px rgba(90, 70, 176, 0.4);
}
.pricing-tab.color-two .monthly_tab_title {
  color: #7052fb;
}
.pricing-tab.color-two.seleceted .annual_tab_title {
  color: #7052fb;
}
.pricing-tab.color-two.seleceted .monthly_tab_title {
  color: #797687;
}

.tabs-wrapper {
  max-width: 830px;
  margin: 0 auto;
}

.faq-tabs {
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 22;
}
.faq-tabs li {
  margin-bottom: 20px;
}
.faq-tabs li:not(:last-child) {
  margin-right: 20px;
}
.faq-tabs li a {
  font-size: 14px;
  font-weight: 600;
  color: #ffc107;
  background: rgba(255, 193, 7, 0.122);
  padding: 1px 25px;
  border-radius: 4px;
  display: inline-block;
}
.faq-tabs li a:hover, .faq-tabs li a.active {
  background: #ffc107;
  color: #fff;
}
.faq-tabs li:nth-child(2) a {
  background: rgba(94, 103, 225, 0.122);
  color: #5e67e1;
}
.faq-tabs li:nth-child(2) a:hover, .faq-tabs li:nth-child(2) a.active {
  background: #5e67e1;
  color: white;
}
.faq-tabs li:nth-child(3) a {
  background: rgba(26, 201, 88, 0.122);
  color: #1ac958;
}
.faq-tabs li:nth-child(3) a:hover, .faq-tabs li:nth-child(3) a.active {
  background: #1ac958;
  color: white;
}
.faq-tabs li:nth-child(4) a {
  background: rgba(251, 43, 224, 0.122);
  color: #fb2be0;
}
.faq-tabs li:nth-child(4) a:hover, .faq-tabs li:nth-child(4) a.active {
  background: #fb2be0;
  color: white;
}

.pricing-service {
  background: #fffbe9;
  padding: 116px 0 90px;
}

@media (max-width: 991px) {
  .pricing {
    padding: 76px 0 80px;
  }
  .pricing-service {
    padding: 76px 0 50px;
  }
  .pricing-single-one,
  .pricing-two-single {
    padding: 76px 0 50px;
  }
  .pricing-tab {
    margin-bottom: 40px;
  }
  .pricing-table {
    border: 1px solid #efe7e7;
    padding: 40px;
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .faq-tabs {
    margin-bottom: 30px;
  }
  .pricing-two {
    padding-top: 66px;
    padding-bottom: 50px;
  }
  .pricing .scroll-circle {
    width: 250px;
  }
}
@media (max-width: 768px) {
  .faq-tabs li {
    margin-bottom: 10px;
  }
}
.faq-section {
  padding-top: 115px;
}

.faq-section-two {
  padding-top: 75px;
}

.faqs {
  padding: 120px 0;
}

.card {
  border: 0;
  background: transparent;
}
.card .card-header {
  background-color: transparent;
  border: 0;
  border-radius: 6px;
}
.card .card-header .btn-link {
  padding: 0;
  color: #2b2350;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}
.card .card-body {
  padding: 0;
}
.card .card-body p {
  font-size: 14px;
  margin-bottom: 25px;
  color: #797687;
}

.faq {
  position: relative;
  z-index: 2;
}
.faq .card {
  background: transparent;
  margin-bottom: 20px;
  border: 1px solid #efe7e7;
}
.faq .card .card-header {
  padding: 0;
}
.faq .card .card-header h5 {
  position: relative;
  margin: 0;
}
.faq .card .card-header .btn-link {
  color: #2b2350;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  white-space: normal;
  text-align: left;
  width: 100%;
  padding: 18px 40px;
}
.faq .card .card-header .btn-link:before {
  content: "\f077";
  display: block;
  position: absolute;
  top: 50%;
  right: 40px;
  z-index: 9;
  font-family: "Font Awesome 5 Pro", sans-serif;
  transition: all 0.5s;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ffc107;
}
.faq .card .card-header .btn-link.collapsed:before {
  content: "\f078";
  color: #aaaaaa;
}
.faq .card .card-body {
  padding: 0 40px 40px;
}
.faq .card .card-body p {
  font-size: 14px;
  margin-bottom: 0;
}
.faq .card.active {
  box-shadow: 0 60px 100px 0 rgba(79, 35, 35, 0.1);
}

.faq-forms {
  padding-top: 120px;
}
.faq-forms .section-title {
  margin-bottom: 50px;
}

.faq-forms {
  max-width: 830px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  #faqs {
    padding: 80px 0 50px;
  }
  .faq-two {
    margin-bottom: 30px;
  }
  .faq-section-two {
    padding-top: 65px;
  }
  .faq-section {
    padding-top: 75px;
  }
  .faqs {
    padding: 80px 0;
  }
  .faq-forms {
    padding-top: 78px;
  }
}
@media (max-width: 768px) {
  #faqs {
    padding: 50px 0 20px;
  }
  .faq .card .card-header h5:before {
    right: 20px;
  }
  .faq .card .card-header .btn-link {
    padding: 18px 20px;
  }
}
@media (max-width: 576px) {
  .faq .card .card-header .btn-link {
    padding: 18px 30px 18px 20px;
    font-size: 18px;
  }
  .faq .card .card-header h5:before {
    right: 10px;
  }
  .faq .card .card-body {
    padding: 5px 20px 25px;
  }
}
.call-to-action {
  background-image: linear-gradient(100deg, #4f4f4f 0%, #000000 100%);
  padding: 111px 0 120px;
  overflow: hidden;
}
.call-to-action .overlay-bg {
  position: absolute;
  right: 0;
  top: 0;
}
.call-to-action .action-content {
  position: relative;
}
.call-to-action .action-content .title {
  color: #fff;
  font-size: 50px;
  line-height: 64px;
  font-weight: 700;
  margin-bottom: 28px;
}
.call-to-action .action-content p {
  color: #fff;
  margin-bottom: 50px;
}
.call-to-action .action-content .pix-btn {
  padding: 12px 50px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  box-shadow: none;
}
.call-to-action .action-content.style-two .title {
  font-size: 40px;
  line-height: 54px;
  margin: 0;
}
.call-to-action.action-padding {
  padding: 172px 0 168px;
}
.call-to-action .scroll-circle {
  right: 0;
  top: 40%;
}

.signup-section {
  padding: 106px 0 111px;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-image: linear-gradient(0deg, rgb(79, 41, 221) 0%, rgb(112, 82, 251) 100%);
  overflow: hidden;
}
.signup-section .bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.signup-section .bg-shape img {
  position: absolute;
}
.signup-section .bg-shape .shape-left {
  top: 0;
  left: 0;
}
.signup-section .bg-shape .shape-right {
  bottom: 0;
  right: 0;
}

.signup-heading .title {
  font-size: 50px;
  line-height: 64px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}
.signup-heading p {
  color: #FFF;
  margin: 0;
}

@media (max-width: 1200px) {
  .call-to-action .action-content.style-two .title {
    font-size: 36px;
    line-height: 50px;
  }
  .signup-heading .title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 991px) {
  .call-to-action {
    padding: 71px 0 80px;
  }
  .call-to-action.action-padding {
    padding: 74px 0 80px;
  }
  .call-to-action .action-content .title {
    font-size: 40px;
    line-height: 54px;
  }
  .call-to-action .text-right {
    text-align: center !important;
  }
  .call-to-action .action-content.style-two {
    margin-bottom: 30px;
    text-align: center;
  }
  .call-to-action .action-content.style-two .title {
    font-size: 32px;
    line-height: 42px;
  }
  .call-to-action .action-content.style-two .title br {
    display: none;
  }
  .call-to-action .scroll-circle {
    max-width: 400px;
  }
  .call-to-action-three {
    padding: 130px 0 100px;
  }
  .call-to-action-three .action-content .title {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  .call-to-action-three .action-content p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .call-to-action .action-content .title {
    font-size: 32px;
    line-height: 42px;
  }
  .call-to-action .action-content p br {
    display: none;
  }
  .call-to-action .scroll-circle {
    max-width: 300px;
  }
  .signup-section .button-container {
    margin-top: 30px;
  }
  .signup-section .button-container.text-right {
    text-align: left !important;
  }
  .call-to-action-three .text-right {
    margin-top: 20px;
    text-align: left !important;
  }
}
.page-banner {
  background: #fffbe9;
  text-align: center;
  height: 350px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.page-banner .container {
  z-index: 99999;
}
.page-banner .page-title {
  font-size: 30px;
  font-weight: 800;
  color: #2b2350;
  position: relative;
  z-index: 2;
  max-width: 820px;
  margin: 130px auto 15px;
}
.page-banner .bradcurmed {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  z-index: 2;
}
.page-banner .bradcurmed li {
  display: inline-block;
  position: relative;
  font-size: 14px;
}
.page-banner .bradcurmed li:not(:last-child) {
  margin-right: 30px;
}
.page-banner .bradcurmed li:not(:last-child):before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: -25px;
  top: 0;
}
.page-banner .bradcurmed li a {
  color: #2b2350;
  font-weight: 500;
}
.page-banner .bradcurmed li a:hover {
  color: #ffc107;
}
.page-banner .tool-description {
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
}
.page-banner .circle {
  position: absolute;
  top: -51%;
  left: -15%;
  z-index: 1;
}
.page-banner.blog-details-banner {
  height: 700px;
}
.page-banner.blog-details-banner .circle {
  position: absolute;
  top: -39%;
  left: -19%;
  z-index: 1;
}
.page-banner.blog-details-banner .page-title-wrapper {
  margin-top: 60px;
}
.page-banner.blog-details-banner .page-title {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
}
.page-banner.blog-details-banner .post-meta {
  position: relative;
  z-index: 22;
  padding: 0;
}
.page-banner.blog-details-banner .post-meta li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  text-transform: uppercase;
}
.page-banner.blog-details-banner .post-meta li:not(:last-child):after {
  position: absolute;
  content: "";
  right: -20px;
  top: 7px;
  width: 2px;
  height: 14px;
  background: #9997a3;
}
.page-banner.blog-details-banner .post-meta li a {
  color: #797687;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 5px;
}
.page-banner.blog-details-banner .post-meta li a:hover {
  color: #ffc107;
}
.page-banner.blog-details-banner .post-meta.color-theme {
  margin-bottom: 10px;
}
.page-banner.blog-details-banner .post-meta.color-theme li a {
  color: #ffc107;
}

.animate-ball {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 0;
  left: 0;
  z-index: 3;
}
.animate-ball .ball {
  background: #fffadd;
  border-radius: 50%;
  position: absolute;
}
.animate-ball .ball:nth-child(1) {
  height: 50px;
  width: 50px;
  top: 37%;
  left: 10%;
  animation: zoommd 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(2) {
  height: 70px;
  width: 70px;
  bottom: 22%;
  right: 8%;
  animation: bounce 4s infinite alternate;
}
.animate-ball .ball:nth-child(3) {
  height: 100px;
  width: 100px;
  bottom: 43%;
  right: 22%;
  animation: zoommd 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(4) {
  height: 40px;
  width: 40px;
  bottom: 20%;
  right: 32%;
  animation: movexy 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(5) {
  height: 500px;
  width: 500px;
  top: -75%;
  right: 100px;
  animation: wave 3s 0.1s infinite linear;
}

.page-banner-contact {
  height: 690px;
  position: relative;
  overflow: hidden;
  background: #fffbe9;
}
.page-banner-contact .circle {
  position: absolute;
  top: -40%;
  left: -15%;
}

.page-title-inner {
  position: relative;
  z-index: 2;
}

.animate-element-contact {
  position: relative;
  z-index: 222;
  height: 465px;
  width: 408px;
  bottom: -135px;
}
.animate-element-contact img {
  position: absolute;
  bottom: 0;
}
.animate-element-contact img:nth-child(1) {
  left: 5px;
  bottom: 55px;
}
.animate-element-contact img:nth-child(2) {
  bottom: 41px;
  left: 128px;
}
.animate-element-contact img:nth-child(3) {
  right: 0;
}
.animate-element-contact img:nth-child(4) {
  bottom: 0;
  left: 110px;
}

.page-banner {
  background: #fffbe9;
  text-align: center;
  height: 350px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.page-banner .page-title {
  font-size: 37px;
  font-weight: 800;
  color: #2b2350;
  position: relative;
  z-index: 2;
  max-width: 820px;
  margin: 130px auto 15px;
}
.page-banner .bradcurmed {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  z-index: 2;
}
.page-banner .bradcurmed li {
  display: inline-block;
  position: relative;
  font-size: 14px;
}
.page-banner .bradcurmed li:not(:last-child) {
  margin-right: 30px;
}
.page-banner .bradcurmed li:not(:last-child):before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: -25px;
  top: 0;
}
.page-banner .bradcurmed li a {
  color: #2b2350;
  font-weight: 500;
}
.page-banner .bradcurmed li a:hover {
  color: #ffc107;
}
.page-banner .circle {
  position: absolute;
  top: -51%;
  left: -15%;
  z-index: 1;
}
.page-banner.blog-details-banner {
  height: 700px;
}
.page-banner.blog-details-banner .circle {
  position: absolute;
  top: -39%;
  left: -19%;
  z-index: 1;
}
.page-banner.blog-details-banner .page-title-wrapper {
  margin-top: 60px;
}
.page-banner.blog-details-banner .page-title {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  margin-bottom: 20px;
}
.page-banner.blog-details-banner .post-meta {
  position: relative;
  z-index: 22;
  padding: 0;
}
.page-banner.blog-details-banner .post-meta li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  text-transform: uppercase;
}
.page-banner.blog-details-banner .post-meta li:not(:last-child):after {
  position: absolute;
  content: "";
  right: -20px;
  top: 7px;
  width: 2px;
  height: 14px;
  background: #9997a3;
}
.page-banner.blog-details-banner .post-meta li a {
  color: #797687;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 5px;
}
.page-banner.blog-details-banner .post-meta li a:hover {
  color: #ffc107;
}
.page-banner.blog-details-banner .post-meta.color-theme {
  margin-bottom: 10px;
}
.page-banner.blog-details-banner .post-meta.color-theme li a {
  color: #ffc107;
}

.animate-ball {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 0;
  left: 0;
}
.animate-ball .ball {
  background: #fffadd;
  border-radius: 50%;
  position: absolute;
}
.animate-ball .ball:nth-child(1) {
  height: 50px;
  width: 50px;
  top: 37%;
  left: 10%;
  animation: zoommd 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(2) {
  height: 70px;
  width: 70px;
  bottom: 22%;
  right: 8%;
  animation: bounce 4s infinite alternate;
}
.animate-ball .ball:nth-child(3) {
  height: 100px;
  width: 100px;
  bottom: 43%;
  right: 22%;
  animation: zoommd 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(4) {
  height: 40px;
  width: 40px;
  bottom: 20%;
  right: 32%;
  animation: movexy 1.5s infinite alternate;
}
.animate-ball .ball:nth-child(5) {
  height: 500px;
  width: 500px;
  top: -75%;
  right: 100px;
  animation: wave 3s 0.1s infinite linear;
}

.page-banner-contact {
  height: 700px;
  position: relative;
  overflow: hidden;
  background: #fffbe9;
  z-index: 2;
}
.page-banner-contact .circle {
  position: absolute;
  top: -40%;
  left: -17%;
}
.page-banner-contact .page-title-wrapper {
  padding: 315px 0 245px;
  position: relative;
}
.page-banner-contact .page-title-wrapper .page-title {
  font-size: 40px;
  line-height: 54px;
}
.page-banner-contact .page-title-wrapper p {
  font-size: 16px;
  line-height: 30px;
}
.page-banner-contact .animate-ball .ball:nth-child(3) {
  bottom: 55%;
  right: 40%;
  height: 70px;
  width: 70px;
}
.page-banner-contact .animate-ball .ball:nth-child(4) {
  bottom: 28%;
  right: 40%;
}
.page-banner-contact .animate-ball .ball:nth-child(5) {
  top: -60%;
}

.animate-element-contact {
  position: relative;
  z-index: 222;
  height: 465px;
  width: 408px;
  bottom: -135px;
}
.animate-element-contact img {
  position: absolute;
  bottom: 0;
}
.animate-element-contact img:nth-child(1) {
  left: 5px;
  bottom: 55px;
}
.animate-element-contact img:nth-child(2) {
  bottom: 41px;
  left: 128px;
}
.animate-element-contact img:nth-child(3) {
  right: 0;
}
.animate-element-contact img:nth-child(4) {
  bottom: 0;
  left: 110px;
}

@media (max-width: 1280px) {
  .animate-element-contact {
    margin-left: -80px;
  }
}
@media (max-width: 1200px) {
  .page-banner {
    height: 400px;
  }
  .page-banner .circle {
    top: -110%;
    left: -35%;
  }
  .animate-ball .ball:nth-child(5) {
    top: -102%;
    right: 0;
  }
  .animate-element-contact {
    margin-left: -80px;
  }
}
@media (max-width: 1024px) {
  .circle {
    display: none;
  }
}
@media (max-width: 991px) {
  .page-banner {
    height: 400px;
  }
  .page-banner .page-banner .circle {
    top: -185%;
    left: -45%;
  }
  .page-banner .page-title {
    font-size: 40px;
    margin: 40px auto 15px;
  }
  .page-banner .animate-ball .ball:nth-child(3) {
    height: 50px;
    width: 50px;
  }
  .page-banner .animate-ball .ball:nth-child(3) {
    height: 40px;
    width: 40px;
  }
  .page-banner .animate-ball .ball:nth-child(5) {
    top: -190px;
    right: 0;
    height: 250px;
    width: 250px;
  }
  .page-banner.blog-details-banner {
    height: 400px;
  }
  .page-banner.blog-details-banner .page-title {
    font-size: 30px;
    line-height: 44px;
  }
  .page-banner.blog-details-banner .circle {
    left: -50%;
  }
  .page-banner-contact {
    height: 800px;
  }
  .page-banner-contact .page-title-wrapper {
    padding: 130px 0 0;
  }
  .animate-element-contact {
    height: 365px;
    margin: 0 auto;
  }
  .page-banner .circle {
    left: -60%;
  }
}
@media (max-width: 768px) {
  .page-banner .page-title {
    font-size: 30px;
    margin: 40px auto 10px;
  }
  .page-banner.blog-details-banner {
    height: 400px;
  }
  .page-banner.blog-details-banner .page-title {
    font-size: 26px;
    line-height: 36px;
  }
  .page-banner.blog-details-banner .circle {
    left: -70%;
  }
  .page-banner-contact .page-title-wrapper {
    padding: 100px 0 0;
  }
  .page-banner-contact .page-title-wrapper .page-title {
    font-size: 32px;
  }
  .page-banner-contact .page-title-wrapper p br {
    display: none;
  }
  .page-banner .circle {
    left: -80%;
  }
}
@media (max-width: 576px) {
  .page-banner .page-title {
    font-size: 24px;
  }
  .page-banner .circle {
    left: -120%;
  }
}
.about-content {
  padding-right: 130px;
}
.about-content .section-title {
  margin-bottom: 35px;
}
.about-content p {
  margin-bottom: 40px;
}
.about-content .singiture h4 {
  font-size: 20px;
  margin-bottom: 25px;
}

@media (max-width: 1200px) {
  .about-content-two .section-title .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 991px) {
  .about {
    padding-top: 76px;
  }
  .about-content {
    padding-right: 0;
  }
  .about-thumb {
    margin-top: 30px;
  }
  .about-two {
    padding-bottom: 80px;
  }
  .about-content-two {
    margin-top: 40px;
  }
  .about-tax {
    padding: 60px 0 80px;
  }
  .about-tax-content {
    padding-left: 0;
    margin-top: 40px;
  }
}
#tools {
  padding: 120px 0;
}
#tools .select-area {
  text-align: center;
  width: 100%;
  margin-top: -25px;
  display: block;
}
#tools .select-area select {
  background: #fdfafa;
  border: 1px solid #efe7e7;
  border-radius: 30px;
  text-align: center;
  padding: 15px 30px;
}
#tools form button#send-btn {
  margin-top: 30px;
  border: 0;
  padding: 13px 90px;
}

.tag-list {
  width: 100%;
}
.tag-list thead {
  line-height: 50px;
  border-bottom: 1px dotted #333;
}
.tag-list thead th {
  padding-left: 20px;
}
.tag-list thead th:first-child {
  width: 70%;
}
.tag-list thead th:last-child {
  width: 30%;
}
.tag-list tbody tr {
  border-bottom: 1px dotted #333;
}
.tag-list tbody tr td {
  padding-left: 20px;
  line-height: 35px;
}

.formated-list-of-tags {
  margin: 20px auto 30px;
  font-size: 14px;
}

.table {
  width: 100%;
  margin: 30px auto;
}
.table .convert-all {
  float: right;
  margin: 10px 0 20px;
  color: #ffc107;
  text-transform: uppercase;
  font-size: 14px;
}
.table table#imageTable {
  width: 100%;
}
.table table#imageTable a.image_delete i {
  color: red;
}
.table table#imageTable a.download-image {
  color: #ffc107;
}
.table .delete_all,
.table .download_all {
  font-size: 13px;
  color: #333;
  text-transform: uppercase;
}
.table .download_all {
  float: right;
}

.related-keyword {
  font-size: 14px;
  color: #4d4d4d;
}
.related-keyword .unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  pointer-events: none;
  position: relative;
  top: -0.5em;
  font-size: 70%;
}

#tools .select-area {
  margin-top: 16px;
}
#tools .content-area {
  margin-top: 50px;
}
#tools form button#send-btn {
  width: 100%;
}
#tools table thead tr th {
  font-size: 15px;
}
#tools table tbody tr td {
  font-size: 13.5px;
  vertical-align: middle;
  position: relative;
}

.chart-tooltip {
  transition: opacity 0.15s ease;
}

/* Align metric columns right */
#generated_tags table td:nth-child(n+2),
#generated_tags table th:nth-child(n+2) {
  text-align: right;
}

/* Keyword always left aligned */
#generated_tags table td:first-child,
#generated_tags table th:first-child {
  text-align: left;
}

.blurred {
  cursor: not-allowed;
}
.blurred > .text-muted {
  filter: blur(4px);
}

.cta-wrapper-relative {
  position: relative;
  padding: 0;
}

.keyword-cta-absolute {
  position: absolute;
  right: 0;
  top: 0;
  width: 75%;
  padding: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

@media (max-width: 991px) {
  .about {
    padding: 76px 0;
  }
  #tools .select-area {
    margin-top: 16px;
  }
  #tools .content-area {
    margin-top: 50px;
  }
  #tools form button#send-btn {
    width: 100%;
  }
  #tools table thead tr th {
    font-size: 15px;
  }
  #tools table tbody tr td {
    font-size: 13px;
  }
}
.contactus {
  padding: 120px 0;
}

.gmap3-area {
  height: 500px;
}

.contact-infos .contact-info {
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.contact-infos .contact-info:first-child {
  border-bottom: 1px solid #efe7e7;
}
.contact-infos .contact-info .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.contact-infos .contact-info .description {
  font-size: 16px;
  line-height: 28px;
}
.contact-infos .contact-info .info i {
  margin-right: 10px;
}
.contact-infos .contact-info .info.phone {
  color: #ffc107;
}

@media (max-width: 991px) {
  .contactus {
    padding: 80px 0 80px;
  }
}
#blog-grid {
  padding: 72px 0 90px;
}
#blog-grid .feature-image a {
  display: block;
  position: relative;
  overflow: hidden;
}
#blog-grid .feature-image a img {
  transition: all 0.3s ease-in-out;
  width: 100%;
}
#blog-grid .feature-image a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 22;
}
#blog-grid .feature-image a:hover:before {
  opacity: 1;
}
#blog-grid .feature-image a:hover img {
  transform: scale(1.05);
}

.blog-post {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}
.blog-post:hover {
  box-shadow: 0px 30px 60px 0px rgba(43, 35, 79, 0.12);
}

.blog-content {
  padding: 22px 45px 30px;
  background: #fff;
}
.blog-content .post-meta {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-content .post-meta li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}
.blog-content .post-meta li:not(:last-child):after {
  position: absolute;
  content: "";
  right: -18px;
  top: 7px;
  width: 2px;
  height: 14px;
  background: #9997a3;
}
.blog-content .post-meta li i {
  margin-right: 5px;
  color: #ffc107;
}
.blog-content .post-meta li a {
  color: #797687;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.blog-content .post-meta li a:hover {
  color: #ffc107;
}
.blog-content .entry-title {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 32px;
  line-height: 30px;
}
.blog-content .entry-title a {
  color: #222;
}
.blog-content .entry-title a:hover {
  color: #ffc107;
}
.blog-content .read-more-ntn {
  font-size: 12px;
  font-weight: 600;
  color: #ffc107;
  text-transform: uppercase;
  display: flex;
}
.blog-content .read-more-ntn i {
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}
.blog-content .read-more-ntn:hover {
  color: #d39e00;
}
.blog-content .read-more-ntn:hover i {
  margin-left: 8px;
}

.color-two .blog-content .post-meta li a:hover {
  color: #7052fb;
}
.color-two .blog-content .entry-title a:hover {
  color: #7052fb;
}

.post-author {
  font-size: 16px;
  font-weight: 500;
  color: #656177;
}
.post-author img {
  border-radius: 50%;
  margin-right: 20px;
}
.post-author:hover {
  color: #7052fb;
}

.blog-post-archive {
  padding: 120px 0;
}

.post-wrapper {
  max-width: 670px;
}
.post-wrapper .categories {
  position: absolute;
  top: 40px;
  right: 0;
}
.post-wrapper .category-item {
  font-size: 16px;
  font-weight: 700;
  background: #f8f6f2;
  padding: 9px 17px;
  color: #827f79;
  margin-left: 5px;
}
.post-wrapper .category-item:hover {
  background: #2b2350;
  color: #fff;
}
.post-wrapper .post {
  margin-bottom: 60px;
}
.post-wrapper .post:last-child .post {
  margin-bottom: 0;
}

.post .feature-image {
  border-radius: 6px;
  overflow: hidden;
}
.post .blog-content {
  padding: 28px 0 40px;
  position: relative;
  border-bottom: 1px solid #e6e5e0;
}
.post .blog-content .post-meta li a {
  color: #797687;
  font-size: 15px;
  margin-right: 3px;
}
.post .blog-content .post-meta li a:hover {
  color: #ffc107;
}
.post .blog-content .entry-title {
  margin: 14px 0 22px;
  font-size: 30px;
  line-height: 1.2;
}
.post .blog-content p {
  margin-bottom: 35px;
}
.post .blog-content .read-more {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #2b2350;
  position: relative;
}
.post .blog-content .read-more i {
  transition: all 0.3s ease-in-out;
  vertical-align: middle;
  font-size: 16px;
}
.post .blog-content .read-more:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background: #ffc107;
  transition: all 0.3s ease-in-out;
}
.post .blog-content .read-more:hover {
  color: #ffc107;
}
.post .blog-content .read-more:hover i {
  margin-left: 3px;
  color: #ffc107;
}
.post .blog-content .read-more:hover:after {
  width: 100%;
}
.post .author {
  position: absolute;
  right: 0;
  bottom: 40px;
}
.post .author img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.post .author span {
  font-size: 16px;
  font-weight: 500;
}
.post .author a {
  font-size: 16px;
  font-weight: 500;
  color: #2b2350;
}
.post .author a:hover {
  color: #ffc107;
}
.post.video-post .feature-image {
  position: relative;
}
.post.video-post .feature-image .video-btn {
  height: 80px;
  width: 80px;
  background: #fff;
  border-radius: 50%;
  line-height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.post.video-post .feature-image .video-btn:before, .post.video-post .feature-image .video-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  border-radius: 50%;
  animation: pulse 2s linear infinite;
}
.post.video-post .feature-image .video-btn:after {
  animation-delay: 1s;
}
.post.video-post .feature-image .video-btn i {
  color: #2b2350;
  font-size: 16px;
  font-size: 40px;
  line-height: 2.05;
}
.post.link-post .blog-content {
  background: #fffbe9;
  padding: 50px 50px;
  border-bottom: 0;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}
.post.link-post .blog-content:before, .post.link-post .blog-content:after {
  content: "\e02c";
  font-family: "ElegantIcons";
  position: absolute;
}
.post.link-post .blog-content:before {
  left: 28px;
  top: 24px;
  color: #f7eded;
  font-size: 50px;
  z-index: 1;
}
.post.link-post .blog-content:after {
  right: 0;
  bottom: -3px;
  font-size: 100px;
  color: #f7eded;
}
.post.link-post .blog-content p {
  font-size: 20px;
  font-weight: 0;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  line-height: 34px;
}
.post.link-post .blog-content p a {
  color: #656177;
  font-weight: 500;
}
.post.link-post .blog-content p a:hover {
  color: #ffc107;
}

.blog-post-two {
  margin-bottom: 30px;
}
.blog-post-two .feature-image {
  border-radius: 6px;
  overflow: hidden;
}
.blog-post-two .feature-image img {
  border-radius: 6px;
  width: 100%;
}
.blog-post-two .blog-content {
  padding: 22px 0 0;
  background: transparent;
}
.blog-post-two .blog-content .post-meta li a {
  text-transform: capitalize;
}
.blog-post-two .blog-content .entry-title {
  font-size: 24px;
  margin: 5px 0 15px;
}
.blog-post-two .blog-content p {
  margin-bottom: 30px;
}
.blog-post-two .blog-content .read-more {
  border: 2px solid #7052fb;
  border-radius: 30px;
  padding: 7px 25px;
  font-weight: 500;
  display: inline-block;
  color: #7052fb;
}
.blog-post-two .blog-content .read-more:hover {
  background: #7052fb;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
}

.blog-grid-two {
  padding: 90px 0;
}

.author img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.author span {
  font-size: 16px;
  font-weight: 500;
}
.author a {
  font-size: 16px;
  font-weight: 500;
  color: #2b2350;
}
.author a:hover {
  color: #ffc107;
}

.quote-post {
  position: relative;
  background: #fffbe9;
  padding: 50px;
  border-bottom: 0;
  overflow: hidden;
  border-radius: 6px;
}
.quote-post:before {
  content: "";
  position: absolute;
  left: 28px;
  top: -20px;
  background-image: url(../../media/blog/quote2.png);
  width: 140px;
  height: 80px;
  z-index: 1;
}
.quote-post p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
  color: #656177;
}
.quote-post span {
  font-size: 16px;
  font-weight: 500;
  color: #2b2350;
}
.quote-post .quote {
  position: absolute;
  right: 50px;
  bottom: 35px;
}

.post-post-grid {
  border-radius: 6px;
  box-shadow: 0px 6px 14px 0px rgba(43, 35, 79, 0.1);
  overflow: hidden;
  margin-bottom: 40px;
}
.post-post-grid .feature-image img {
  width: 100%;
}
.post-post-grid .blog-content {
  padding: 22px 40px 30px;
}
.post-post-grid .blog-content .entry-title {
  margin: 8px 0 37px;
  line-height: 30px;
}

.post-navigation {
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-navigation li {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 18px;
  margin-right: 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  color: #797687;
  border-radius: 50%;
  border: 1px solid #e5dada;
}
.post-navigation li a {
  display: block;
  color: #797687;
}
.post-navigation li.prev {
  line-height: 54px;
  font-size: 24px;
}
.post-navigation li.next {
  line-height: 54px;
  font-size: 24px;
}
.post-navigation li:hover {
  border-color: #ffc107;
  background: #ffc107;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
.post-navigation li:hover a {
  color: #fff;
}
.post-navigation li.active {
  background: #ffc107;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}

.blog-single {
  padding-top: 120px;
}
.blog-single .post {
  margin-bottom: 20px;
}
.blog-single .post .blog-content {
  padding: 40px 0 20px;
}
.blog-single .post .blog-content p {
  margin-bottom: 45px;
}
.blog-single .post .blog-content h3 {
  margin-top: 42px;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.blog-single blockquote {
  margin-bottom: 42px;
  display: block;
}
.blog-single .tagcloud span {
  color: #2b2350;
  display: inline-block;
  margin-right: 15px;
  font-size: 20px;
  font-weight: 500;
}
.blog-single .tagcloud a {
  line-height: 25px;
}

.blog-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-share .share-title p {
  color: #2b2350;
  display: inline-block;
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}

.share-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.share-link li {
  display: inline-block;
  margin-right: 5px;
}
.share-link li:last-child {
  margin-right: 0;
}
.share-link li a {
  display: block;
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 45px;
  color: #aba8a4;
  border-radius: 50%;
  font-size: 14px;
  border: 1px solid #e5dada;
}
.share-link li a:hover {
  background: #ffc107;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
  border-color: #ffc107;
}

.pixsass_post_author_box {
  background: #fffbe9;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  border-radius: 6px;
}
.pixsass_post_author_box .profile_image {
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
}
.pixsass_post_author_box .profile_image img {
  border-radius: 50%;
}
.pixsass_post_author_box .profile_content {
  flex: 1;
}
.pixsass_post_author_box .profile_content .profile_name {
  font-size: 20px;
  line-height: 28px;
  color: #2b2350;
  margin: 0;
}
.pixsass_post_author_box .profile_content .author-job {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  display: inline-block;
}
.pixsass_post_author_box .profile_content p {
  font-size: 18px;
  color: #797687;
  margin: 0;
  font-weight: 500;
}

.comment-area {
  padding: 110px 0 120px;
}
.comment-area .coment-title {
  font-size: 24px;
  font-weight: 600;
  color: #2b2350;
  margin-bottom: 70px;
}

.comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.comment-list .comment .comment-body {
  display: flex;
  margin-bottom: 40px;
}
.comment-list .comment .comment-body .comment-author {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 30px;
}
.comment-list .comment .comment-body .comment-author img {
  border-radius: 50%;
}
.comment-list .comment .comment-body .comment-content {
  border-bottom: 1px solid #e6e5e0;
  padding-bottom: 30px;
  position: relative;
}
.comment-list .comment .comment-body .comment-content h4 {
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 16px;
}
.comment-list .comment .comment-body .comment-content h4 a {
  color: #2b2350;
}
.comment-list .comment .comment-body .comment-content h4 a:hover {
  color: #ffc107;
}
.comment-list .comment .comment-body .comment-content .comment-reply-link {
  color: #2b2350;
  font-size: 15px;
  font-weight: 500;
}
.comment-list .comment .comment-body .comment-content .comment-reply-link i {
  margin-right: 6px;
  font-size: 16px;
}
.comment-list .comment .comment-body .comment-content .comment-reply-link:hover {
  color: #ffc107;
}
.comment-list .comment .comment-body .comment-content .comment-metadata {
  margin-bottom: 10px;
}
.comment-list .comment .comment-body .comment-content .comment-metadata a {
  font-size: 16px;
  color: #918f9c;
  font-weight: 400;
  line-height: 28px;
}
.comment-list .comment .comment-body .comment-content .comment-metadata a:hover {
  color: #ffc107;
}
.comment-list .comment .comment-body.menu-comments .comment-author {
  width: 100px;
  height: 100px;
}
.comment-list .comment .comment-body.menu-comments .comment-author img {
  width: 100%;
}
.comment-list .children {
  margin: 0;
  padding-left: 150px;
  list-style: none;
}
.comment-list .children .comment .comment-body .comment-author {
  width: 50px;
  height: 50px;
}

.comment-respond .comment-reply-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 42px;
}

.comment-form .comment-form-author,
.comment-form .comment-form-email {
  width: 50%;
  float: left;
  margin-bottom: 30px;
}
.comment-form .comment-form-author {
  padding-right: 15px;
}
.comment-form .comment-form-email {
  padding-left: 15px;
}
.comment-form input,
.comment-form textarea {
  background: #fff;
  border: 0;
  font-size: 16px;
  padding: 15px 30px;
  background: #fdfafa;
  border: 1px solid #efe7e7;
  margin: 0;
}
.comment-form input::placeholder,
.comment-form textarea::placeholder {
  color: #a7a5b1;
  transition: all 0.3s ease-in-out;
}
.comment-form input:focus,
.comment-form textarea:focus {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
}
.comment-form .comment-form-comment {
  margin-bottom: 21px;
}
.comment-form textarea {
  height: 200px;
  border-radius: 20px;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-footer input {
  width: 15px;
  margin-right: 10px;
}
.form-footer .condition {
  display: flex;
  align-items: center;
}
.form-footer .condition span {
  font-size: 14px;
}
.form-footer .submit-btn {
  padding: 8px 45px;
}

.circle-blog {
  position: absolute;
  top: -11%;
  left: -11%;
  z-index: -1;
}

@media (max-width: 991px) {
  #blog-grid {
    padding: 19px 0 50px;
  }
  .blog-post-archive {
    padding: 80px 0 70px;
  }
  .blog-single {
    padding-top: 80px;
  }
  .sidebar {
    margin-top: 40px;
  }
  .comment-area {
    padding: 70px 0 80px;
  }
  .form-footer {
    display: block;
    align-items: center;
    justify-content: space-between;
  }
  .form-footer input {
    width: 15px;
    margin-right: 10px;
  }
  .form-footer .condition {
    margin-bottom: 30px;
  }
  .form-footer .condition span {
    font-size: 14px;
  }
  .form-footer .submit-btn {
    padding: 8px 45px;
  }
  .blog-grid-two {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
@media (max-width: 768px) {
  .form-footer .condition {
    align-items: normal;
  }
  .form-footer .condition span {
    margin-top: -6px;
  }
  .circle-blog {
    display: none;
  }
}
@media (max-width: 576px) {
  .comment-list .children {
    padding-left: 95px;
  }
  .comment-list .children .comment .comment-body .comment-author {
    margin-right: 15px;
  }
  .countup .scroll-circle {
    display: none;
  }
}
@media (max-width: 480px) {
  .comment-form .comment-form-author,
  .comment-form .comment-form-email {
    width: 100%;
    padding: 0;
  }
}
.widget:not(:last-child) {
  margin-bottom: 50px;
}
.widget .widget-title {
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #ebe3e3;
  padding-bottom: 13px;
  margin-bottom: 25px;
}
.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget ul li a {
  font-size: 16px;
  line-height: 40px;
  color: #797687;
  font-weight: 500;
}
.widget ul li a:hover {
  color: #ffc107;
}
.widget .social-share-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.widget .social-share-link li a {
  display: block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: #ffc107;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  font-size: 14px;
}
.widget .social-share-link li a:hover {
  transform: translateY(-5px);
  color: #fff;
}
.widget .social-share-link li a.share_facebook {
  background: #4267b2;
}
.widget .social-share-link li a.share_twitter {
  background: #1da1f2;
}
.widget .social-share-link li a.share_pinterest {
  background: #e60023;
}
.widget .social-share-link li a.share_linkedin {
  background: #0073b1;
}

.social-sign-up {
  margin: 0 auto 20px;
}
.social-sign-up .social-button-wrappers {
  width: 100%;
  text-align: center;
}
.social-sign-up .social-button-wrappers .social-icons {
  color: #fff;
  text-align: center;
  padding: 14px 42px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  width: 49.5%;
}
.social-sign-up .social-button-wrappers #customGBtn {
  background-color: #4285f4;
}
.social-sign-up .social-button-wrappers #customGBtn:hover {
  background-color: #6199f6;
}
.social-sign-up .social-button-wrappers #customGBtn .icon {
  max-width: 24px;
  fill: #fff;
  margin-right: 10px;
  margin-top: -5px;
  display: inline-block;
}
.social-sign-up .social-button-wrappers #customFbBtn {
  background-color: #3c5a99;
}
.social-sign-up .social-button-wrappers #customFbBtn:hover {
  background-color: #4568b1;
}
.social-sign-up .social-button-wrappers #customFbBtn .icon {
  max-width: 24px;
  fill: #fff;
  margin-right: 10px;
  margin-top: -5px;
  display: inline-block;
}
.social-sign-up .social-button-wrappers #customABtn {
  background-color: #31465f;
}
.social-sign-up .social-button-wrappers #customABtn:hover {
  background-color: #415d7e;
}
.social-sign-up .social-button-wrappers #customABtn .icon {
  max-width: 24px;
  fill: #fff;
  margin-right: 10px;
  margin-top: -5px;
  display: inline-block;
}

p.text-tag {
  margin: 20px auto 5px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
p.text-tag span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.75em;
}
p.text-tag span::before {
  left: 100%;
}
p.text-tag span::after {
  right: 100%;
}
p.text-tag span::before, p.text-tag span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #d3d3d3;
}

.signin {
  height: 1050px;
  position: relative;
  overflow: hidden;
}
.signin .circle {
  position: absolute;
  left: -17%;
  top: -33%;
}
.signin .animate-ball .ball {
  background: #fffbe5;
}
.signin .animate-ball .ball:nth-child(1) {
  top: 22%;
  left: -30%;
}
.signin .animate-ball .ball:nth-child(2) {
  height: 60px;
  width: 60px;
}
.signin .animate-ball .ball:nth-child(3) {
  bottom: 110px;
  right: auto;
  left: -20%;
  height: 80px;
  width: 80px;
}
.signin .animate-ball .ball:nth-child(4) {
  bottom: 30%;
  right: auto;
  left: -41%;
}
.signin .animate-ball .ball:nth-child(5) {
  top: auto;
  right: 100px;
  bottom: -430px;
}

.signin-from-wrapper {
  height: 1050px;
  position: relative;
  z-index: 22;
}
.signin-from-wrapper .signin-from-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 100px;
  position: relative;
  z-index: 222;
}
.signin-from-wrapper .signin-from-inner .title {
  font-size: 36px;
}
.signin-from-wrapper .signin-from-inner input:focus {
  box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.signin-from-wrapper .signin-from-inner button {
  border: 0;
  padding: 11px 67px;
  outline: 0;
  margin-bottom: 33px;
  margin-top: 55px;
}
.signin-from-wrapper .signin-from-inner p {
  margin-top: 10px;
  margin-bottom: 38px;
  font-size: 18px;
}
.signin-from-wrapper .signin-from-inner p a {
  color: #ffc107;
}

.signin-banner {
  background: #fef8f8;
  position: absolute;
  right: 0;
  height: 100%;
  max-width: 48%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signin-banner .image-two {
  margin-left: -35px;
  margin-top: -140px;
}
.signin-banner.signup-banner {
  justify-content: flex-start;
}
.signin-banner.signup-banner .image-one,
.signin-banner.signup-banner .image-two {
  position: absolute;
  bottom: 0;
  margin: 0;
}
.signin-banner.signup-banner .image-one {
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.signin-banner.signup-banner .animate-image-inner {
  position: absolute;
  width: 100%;
  height: 450px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.condition {
  display: flex;
  align-items: center;
}
.condition .styled-checkbox {
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 16px;
  margin: 0;
}
.condition span {
  font-size: 14px;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
  height: 20px;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  border: 2px solid #aba8a4;
  transition: all 0.3s ease-in-out;
}

.styled-checkbox:hover + label:before {
  border-color: #ffc107;
}

.styled-checkbox:checked + label:before {
  border-color: #ffc107;
}

.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 9px;
  background: #ffc107;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #ffc107, 4px 0 0 #ffc107, 4px -2px 0 #ffc107, 4px -4px 0 #ffc107, 4px -6px 0 #ffc107, 4px -8px 0 #ffc107;
  transform: rotate(45deg);
}

.forget-link {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.forget-link .forget {
  font-size: 14px;
  color: #9694a2;
}
.forget-link .forget:hover {
  color: #ffc107;
}

.singup-social {
  margin-bottom: 25px;
  padding: 0;
  list-style: none;
}
.singup-social li {
  display: inline-block;
  margin-bottom: 10px;
}
.singup-social li:not(:last-child) {
  margin-right: 4px;
}
.singup-social li a {
  display: inline-block;
  padding: 11px 35px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  width: 180px;
  text-align: center;
}
.singup-social li a i {
  margin-right: 10px;
}
.singup-social li a:hover {
  opacity: 0.8;
}
.singup-social li:nth-child(1) a {
  background: #3b5998;
}
.singup-social li:nth-child(2) a {
  background: #dd4b39;
}
.singup-social li:nth-child(3) a {
  background: #55acee;
}

@media (max-width: 991px) {
  .signin-from-wrapper .signin-from-inner {
    transform: translateY(-47%);
  }
  .signin-from-wrapper .signin-from-inner .title {
    font-size: 30px;
  }
  .signup input {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .signin {
    height: auto;
  }
  .signin-from-wrapper {
    height: auto;
  }
  .signin-from-wrapper .signin-from-inner {
    position: relative;
    height: auto;
    z-index: 22;
    padding: 150px 0 36px;
    top: 0;
    transform: translateY(0);
  }
  .signin-from-wrapper .signin-from-inner .title {
    font-size: 30px;
  }
  .signin-banner {
    position: relative;
    max-width: 100%;
    z-index: 222;
    padding: 80px 0;
  }
  .signin-banner .image-one {
    max-width: 200px;
  }
  .signin-banner .image-two {
    max-width: 80px;
    margin-left: -20px;
    margin-top: -50px;
  }
  .signin-banner.signup-banner {
    height: 350px;
  }
  .signin-banner.signup-banner .animate-image-inner {
    height: 200px;
  }
  .signin-banner.signup-banner .animate-image-inner .image-two img {
    width: 60px;
  }
}
@media (max-width: 768px) {
  .signin-from-wrapper .signin-from-inner {
    padding: 100px 0 5px;
  }
  .signin-banner.signup-banner {
    padding: 50px 0;
    height: 300px;
  }
}
.error-page {
  background: #fef9f9;
  padding: 185px 0 165px;
}

.error-content-wrapper img {
  margin-bottom: 30px;
}
.error-content-wrapper .error-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.3;
}
.error-content-wrapper p {
  font-size: 20px;
  margin-bottom: 47px;
}
.error-content-wrapper .pix-btn {
  padding: 11px 67px;
}

@media (max-width: 991px) {
  .error-page {
    background: #fef9f9;
    padding: 130px 0 80px;
  }
  .error-content-wrapper .error-title {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.3;
  }
  .error-content-wrapper p {
    font-size: 16px;
  }
}
.intercom-namespace-internal {
  position: fixed;
  bottom: 48px;
  right: 28px;
  padding: 10px 20px;
}

.intercom-qd0u5 {
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgb(244, 186, 20);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  backface-visibility: hidden;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}
.intercom-qd0u5 * {
  cursor: pointer;
}
.intercom-qd0u5 .intercom-1u7xwp5,
.intercom-qd0u5 .intercom-1epm6qj {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
}
.intercom-qd0u5 .intercom-1u7xwp5 {
  -webkit-box-pack: center;
  opacity: 1;
  transform: rotate(0deg) scale(1);
}
.intercom-qd0u5 .intercom-1u7xwp5 svg {
  width: 24px;
  height: 24px;
}
.intercom-qd0u5 .intercom-1u7xwp5 svg path {
  fill: rgb(255, 255, 255);
}
.intercom-qd0u5 .intercom-1epm6qj {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  opacity: 0;
  transform: rotate(-60deg);
}
.intercom-qd0u5 .intercom-1epm6qj svg path {
  fill: rgb(255, 255, 255);
}

#footer {
  background: #f1f1f1;
}
#footer .footer-inner {
  padding: 116px 0 62px;
}
#footer .widget.footer-widget .widget-title {
  color: #2b2350;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 38px;
  border-bottom: 0;
  padding-bottom: 0;
}
#footer .widget.footer-widget p {
  margin-bottom: 40px;
}
#footer .footer-menu {
  margin-bottom: 40px;
  padding: 0;
  list-style: none;
}
#footer .footer-menu li:not(:last-child) {
  margin-bottom: 10px;
}
#footer .footer-menu li a {
  display: block;
  color: #797687;
  font-weight: 400;
  line-height: 30px;
}
#footer .footer-menu li a:hover {
  color: #ffc107;
}
#footer .footer-logo {
  display: block;
  margin-bottom: 30px;
}
#footer .email-info {
  display: block;
  color: #ffc107;
  margin-bottom: 20px;
}
#footer .email-info i {
  margin-right: 10px;
}
#footer .widget-contact .widget-contact-info {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 240px;
}
#footer .widget-contact .widget-contact-info li {
  color: #b5b3be;
  font-weight: 300;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}
#footer .widget-contact .widget-contact-info li i {
  margin-right: 10px;
  color: #12bf7c;
}
#footer .copyright-text {
  margin-bottom: 50px;
}
#footer .copyright-text a {
  color: #ffc107;
}
#footer .footer-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
#footer .footer-social-link li {
  display: inline-block;
}
#footer .footer-social-link li:not(:last-child) {
  margin-right: 10px;
}
#footer .footer-social-link li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  border: 1px solid #e6dbdb;
  display: block;
  border-radius: 50%;
  color: #9694a1;
  text-align: center;
  font-size: 16px;
}
#footer .footer-social-link li a:hover {
  background: #ffc107;
  border-color: #ffc107;
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
}
#footer .site-info {
  padding: 36px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4d9d9;
  position: relative;
  z-index: 3;
}
#footer .site-info p {
  margin: 0;
}
#footer .site-info p a {
  color: #ffc107;
  font-weight: 500;
}
#footer .site-info p a:hover {
  color: #d39e00;
}
#footer.footer-two {
  background: #f8f7fc;
}
#footer.footer-two .footer-menu li a:hover {
  color: #7052fb;
}
#footer.footer-two .footer-social-link li a:hover {
  background: #7052fb;
  border-color: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
}
#footer.footer-two .site-info {
  border-color: #dad8e3;
}
#footer.footer-two .site-info p a {
  color: #7052fb;
}
#footer.footer-two .site-info p a:hover {
  color: #4720fa;
}
#footer.footer-two .footer-social-link li a {
  border-color: #dad8e3;
}
#footer.footer-two .site-info-menu li a:hover {
  color: #7052fb;
}
#footer.footer-three {
  position: relative;
}
#footer.footer-three .footer-inner {
  position: relative;
  z-index: 3;
}
#footer.footer-three .map-bg {
  text-align: center;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 0;
  width: 100%;
}
#footer.footer-four .site-info {
  background: #f8f2f2;
  border-top: 1px solid #e4d9d9;
  padding: 25px 0;
}
#footer.footer-four .site-info .site-info-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#footer.footer-five {
  background: #2c234d;
}
#footer.footer-five .widget.footer-widget .widget-title {
  color: #fff;
}
#footer.footer-five .widget p {
  color: #a39eb9;
}
#footer.footer-five .footer-menu li a {
  color: #a39eb9;
}
#footer.footer-five .footer-menu li a:hover {
  color: #7052fb;
}
#footer.footer-five .footer-social-link li a {
  border-color: #615a7e;
  color: #a39eb9;
}
#footer.footer-five .footer-social-link li a:hover {
  background: #7052fb;
  box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
  border-color: #7052fb;
  color: #fff;
}
#footer.footer-five .site-info-menu li a {
  color: #9c99a7;
}
#footer.footer-five .site-info-menu li a:hover {
  color: #7052fb;
}
#footer.footer-five .site-info {
  border-color: rgb(61, 53, 91);
}
#footer.footer-five .site-info p {
  color: #9c99a7;
}
#footer.footer-five .site-info p a {
  color: #7052fb;
}
#footer.footer-tax-service {
  background: #f2f7fe;
  position: relative;
}
#footer.footer-tax-service .footer-inner {
  padding: 116px 0 175px;
}
#footer.footer-tax-service .email-info {
  color: #f97bc5;
  margin-bottom: 20px;
}
#footer.footer-tax-service .copyright-text a {
  color: #f97bc5;
}
#footer.footer-tax-service .footer-social-link li a {
  border-color: #d6dee9;
  color: #9198a4;
}
#footer.footer-tax-service .footer-social-link li a:hover {
  background: #f97bc5;
  box-shadow: 0px 20px 30px 0px rgba(235, 56, 160, 0.3);
  color: #fff;
}
#footer.footer-tax-service .footer-menu li a {
  color: #505064;
}
#footer.footer-tax-service .footer-menu li a:hover {
  color: #f97bc5;
}
#footer.footer-tax-service .footer-bottom-element .shape-element {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li {
  position: absolute;
  bottom: 0;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(2) {
  bottom: 0;
  left: 70px;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(3) {
  bottom: 0;
  left: 20px;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(4) {
  bottom: 20px;
  left: 40%;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(5) {
  bottom: 0;
  right: 120px;
}
#footer.footer-tax-service .footer-bottom-element .shape-element li:nth-child(6) {
  bottom: 0;
  left: 200px;
}
#footer.footer-job-board {
  background-size: cover;
  background-position: center center;
}
#footer.footer-job-board .footer-inner {
  padding: 115px 0 85px;
}
@media (max-width: 991px) {
  #footer.footer-job-board .footer-inner {
    padding: 75px 0 45px;
  }
}
#footer.footer-job-board .widget.footer-widget .widget-title {
  color: #fff;
}
#footer.footer-job-board .footer-social-link {
  margin-bottom: 22px;
}
#footer.footer-job-board .footer-social-link li a {
  background: rgba(255, 255, 255, 0.078);
  border: 0;
  color: #fff;
}
#footer.footer-job-board .footer-social-link li a:hover {
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(12, 118, 142, 0.2);
  color: #fd4d5c;
}
#footer.footer-job-board .footer-menu li:not(:last-child) {
  margin-bottom: 5px;
}
#footer.footer-job-board .footer-menu li a {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
#footer.footer-job-board .footer-menu li a:hover {
  color: #fd4d5c;
}
#footer.footer-job-board .copyright-text {
  color: rgba(255, 255, 255, 0.502);
}
#footer.footer-job-board .copyright-text a {
  color: #fd4d5c;
}
#footer.footer-app {
  padding-bottom: 50px;
}
#footer.footer-app .widget.footer-widget .widget-title {
  color: #fff;
}
#footer.footer-app .widget.widget-about {
  max-width: 240px;
}
#footer.footer-app .widget.widget-about p {
  color: #b5b3be;
  margin-bottom: 26px;
}
#footer.footer-app .widget.widget-about .footer-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
#footer.footer-app .footer-menu li:not(:last-child) {
  margin-bottom: 5px;
}
#footer.footer-app .footer-menu li a {
  color: #b5b3be;
}
#footer.footer-app .footer-menu li a:hover {
  color: #12bf7c;
}
#footer.footer-app .site-info {
  display: block;
  border-top-color: #2f3255;
  padding: 25px 0;
}
#footer.footer-app .site-info p {
  color: #9997a2;
}
#footer.footer-app .site-info p a {
  color: #12bf7c;
}

.site-info-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-info-menu li {
  display: inline-block;
}
.site-info-menu li a {
  display: inline-block;
  color: #797687;
}
.site-info-menu li a:hover {
  color: #ffc107;
}

@media (max-width: 991px) {
  #footer .footer-inner {
    padding: 76px 0 22px;
  }
  #footer .site-info {
    padding: 21px 0;
  }
  #footer .widget.footer-widget {
    margin-bottom: 30px;
  }
  #footer .widget.footer-widget .widget-title {
    margin-bottom: 25px;
  }
}
@media (max-width: 768px) {
  #footer .footer-menu {
    margin-bottom: 40px;
  }
  #footer .site-info {
    padding: 21px 0;
    display: block;
    text-align: center;
  }
  #footer.footer-four .copyright {
    margin-bottom: 20px;
  }
  #footer.footer-four .site-info .site-info-inner {
    display: block;
  }
}
.inner h3 {
  font-size: 15px;
  color: #212121;
  margin: 15px 0 5px;
}
.inner h2 {
  margin: 40px 0 5px;
  position: relative;
  width: 100%;
  font-size: 18px;
}
.inner p,
.inner ul > li {
  font-size: 14px;
  line-height: 20px;
}