.inner {
    h3 {
        font-size: 15px;
        color: #212121;
        margin: 15px 0 5px;
    }
    h2 {
        margin: 40px 0 5px;
        position: relative;
        width: 100%;
        font-size: 18px;
    }
    p,
    ul > li {
        font-size: 14px;
        line-height: 20px;
    }
}
