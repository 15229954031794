$font__primary: 'Poppins', sans-serif;
$font__heading: 'Poppins', sans-serif;

$color__theme: #ffc107;
$color__theme-2: #7052fb;
$color__theme-3: #fbab41;
$color__theme-4: #f97bc5;
$color__theme-5: #fd4d5c;
$color__theme-6: #12bf7c;
$color__primary: #2b2350;
$color__body: #797687;
$background__bg: #fffbe9;
$background__2: #f8f7fc;

$gradient_background:  linear-gradient(100deg, #ffc107 0%, #ffb507 100%);
$gradient_background_2: linear-gradient(-60deg, rgb(112, 82, 251) 0%, rgb(138, 96, 253) 100%);
$gradient_background_3: linear-gradient(100deg, #222222 0%, #000000 100%);
