#tools{
    padding: 120px 0;
    .select-area {
        text-align: center;
        width: 100%;
        margin-top: -25px;
        display: block;
        select{
            background: #fdfafa;
            border: 1px solid #efe7e7;
            border-radius: 30px;
            text-align: center;
            padding: 15px 30px;
        }
    }
    form{
        button#send-btn{
            margin-top: 30px;
            border: 0;
            padding: 13px 90px;
        }
    }
}

.tag-list {
    width: 100%;
    thead{
        line-height: 50px;
        border-bottom: 1px dotted #333;
        th{
            padding-left: 20px;
            &:first-child{
                width: 70%;
            }
            &:last-child{
                width: 30%;
            }
        }
    }
    tbody{
        tr{
            border-bottom: 1px dotted #333;
            td{
                padding-left: 20px;
                line-height: 35px;
            }
        }
    }
}

.formated-list-of-tags{
    margin: 20px auto 30px;
    font-size: 14px;
}

.table{
    width: 100%;
    margin: 30px auto;
    .convert-all{
        float: right;
        margin: 10px 0 20px;
        color: $color__theme;
        text-transform: uppercase;
        font-size: 14px;
    }
    table#imageTable{
        width: 100%;
        a.image_delete{
            i{
                color: red;
            }
        }
        a.download-image{
            color: $color__theme;
        }
    }
    .delete_all, .download_all{
        font-size: 13px;
        color: #333;
        text-transform: uppercase;
    }
    .download_all{
        float: right;
    }
}

.related-keyword{
    font-size: 14px;
    color: #4d4d4d;
    .unselectable{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #000;
        pointer-events: none;
        position: relative;
        top: -0.5em;
        font-size: 70%;
    }
}

@media (max-width: 991px) {
    .about {
        padding: 76px 0;
    }

    #tools{
        .select-area {
            margin-top: 16px;
        }
        .content-area{
            margin-top: 50px;
        }
        form {
            button#send-btn {
                width: 100%;
            }
        }
    }

}
