.widget {
    &:not(:last-child) {
        margin-bottom: 50px;
    }

    .widget-title {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid #ebe3e3;
        padding-bottom: 13px;
        margin-bottom: 25px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            a {
                font-size: 16px;
                line-height: 40px;
                color: #797687;
                font-weight: 500;

                &:hover {
                    color: $color__theme;
                }
            }
        }
    }

	.social-share-link {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;

	li {
		a {
			display: block;
			height: 35px;
			width: 35px;
			line-height: 35px;
			background: $color__theme;
			color: #fff;
			border-radius: 50%;
			text-align: center;
			margin-right: 10px;
			font-size: 14px;

			&:hover {
				transform: translateY(-5px);
				color: #fff;
			}

			&.share_facebook {
				background: #4267b2;
			}

			&.share_twitter {
				background: #1da1f2;
			}

			&.share_pinterest {
				background: #e60023;
			}

			&.share_linkedin {
				background: #0073b1;
			}
		}
	}
}
}
